export const PUBLIC_WEBSITE = (function() {
    let website = window.location.origin.replace("secureplus", "www");
    website = website.replace("secure", "www");
    return website;
})();


export const TOMCAT_LOCALHOST_BASE_URL = "http://localhost:8085";

// Uncomment the following to point to QA webapi
// export const TOMCAT_LOCALHOST_BASE_URL = "https://qa3-api.moneykey.com";
export const OLD_APPLICATION_PAGE = "https://www.moneykey.com/start-application";
export const OLD_APPLICATION_PAGE_QA = `https://$window.location.host.split("-")[0]}-www.moneykey.com/start-application`;
export const FINANCER_PIXEL_POSTBACK_URL = "https://partners.financer.com/postback";
export const AUTO_DIALER_EXTERNAL_LINK =
"https://cdn.moneykey.com/legal/Use-of-Autodialers-and-SMS-Terms-and-Conditions.pdf";
export const CREDIT_FRESH_AUTO_DIALER_LINK = "https://cdn.creditfresh.com/legal/CreditFresh-Autodialers-and-Text-Messages-Terms-and-Conditions.pdf";
export const MONEYKEY_PRIVACY_POLICY_URL = "https://cdn.moneykey.com/legal/MK-Privacy-Policy.pdf";
export const MONEYKEY_CONTACT_URL = "https://www.moneykey.com/contact-us/";
export const DECISION_LOGIC_PRIVACY_POLICY_URL = "https://www.decisionlogic.com/privacy-policy";
export const FLINKS_PRIVACY_POLICY_URL = "https://flinks.com/privacy-policy/";

export const ORGANIZATION_MONEYKEY = "MoneyKey";
export const ORGANIZATION_CREDIT_FRESH = "CreditFresh";
export const ORGANIZATION_MONEYKEY_PLUS = "MoneyKeyPlus";
export const ORGANIZATION_CREDIT_FRESH_PLUS = "CreditFreshPlus";

// FORA
export const FORA_CUT_OFF_TIMES = "https://www.foracredit.ca/resources/faqs#funding_time";

//PATHWARD
export const PATHWARD_TERMS_OF_USE = "https://cdn.loc.pathward.com/pathward-terms-of-use.pdf";
export const PATHWARD_REVIEW_THE_TERMS = "https://cdn.loc.pathward.com/web/pdf/Pathward-Use-of-Autodialers-and-Text-Messages.pdf";
export const PATHWARD_PRIVACY_POLICY_URL = "https://cdn.loc.pathward.com/pathward-privacy-policy.pdf";

// MKWEBAuthToken
export const HEADER_X_AUTH_TOKEN = "X-Auth-Token";
export const HEADER_X_USERID = "X-UserId";
export const HEADER_X_CUSTOMERID_FE = "X-CustomerId-FE";
export const COOKIE_MKEWBAUTH_TOKEN = "mkwebauthtoken";
export const COOKIE_MKWEBAUTH_DATA = "mkwebauthdata";
export const COOKIE_MKWEBAPPLICATION_DATA = "mkwebapplicationdata";
export const COOKIE_MKWEBDASHBOARD_DATA = "mkwebdashboarddata";
export const COOKIE_MKWEBLOAN_DATA = "mkwebloandata";
export const COOKIE_MKWEBACH_DATA = "achdata";
export const COOKIE_MKWEBINSURANCE_DATA = "mkwebInsuranceData";
export const COOKIE_MK_WEB_MC_IDENTITY_DATA = "mkwebMcidentityData";
export const COOKIE_CROSS_ORG_LOGIN_TOKEN = "xOrgLoginToken";

export const ERROR_FROM_API = "api";

export const MONEYKEY_PHONE_NUMBER = "18662551668";
export const MONEYKEY_PHONE_NUMBER_DASH = "1-866-255-1668";

export const TRANSUNION_PHONE_NUMBER = "1-888-567-8688";

export const CREDITFRESH_COLLECTIONS_PHONE_NUMBER = "1-833-655-0300";

export const CREDIT_FRESH_CC_EMAIL = "mailto:customercare@creditfresh.com";

export const DOMAIN_CREDITFRESH = "creditfresh.com";

// States
export const STATE_OHIO = "OH";
export const STATE_CALIFORNIA = "CA";
export const STATE_TEXAS = "TX";
export const STATE_DELAWARE = "DE";
export const STATE_ALABAMA = "AL";
export const STATE_TENNESSEE = "TN";
export const STATE_KANSAS = "KS";
export const STATE_NEW_MEXICO = "NM";
export const STATE_MISSOURI = "MO";
export const STATE_MISSISSIPPI = "MS";
export const STATE_SOUTH_CAROLINA = "SC";
export const STATE_ILLINOIS = "IL";
export const STATE_WASHINGTON = "WA";
export const STATE_WISCONSIN = "WI";
export const STATE_NEBRASKA = "NE";
export const STATE_BC = "BC";
export const STATE_NB = "NB";
export const STATE_NL = "NL";
export const STATE_NS = "NS";
export const STATE_GA = "GA";
export const STATE_NC = "NC";
export const STATE_VA = "VA";
export const STATE_NJ = "NJ";
export const STATE_MT = "MT";
export const STATE_WY = "WY";
export const STATE_MN = "MN";
export const STATE_ND = "ND";
export const STATE_NV = "NV";
export const STATE_WV = "WV";
export const STATE_NY = "NY";
export const STATE_PA = "PA";
export const STATE_BLOCKED_NC = [STATE_ALABAMA, STATE_CALIFORNIA];

// States with minimum valid age of 19: Alabama, British Columbia, New Brunswick, and Newfoundland & Labrador
export const STATE_MIN_AGE_19 = [STATE_ALABAMA, STATE_BC, STATE_NB, STATE_NL, STATE_NS];

// Entities
export const ENTITY_CA_PAYDAY = 43;

export const ENTITY_DE_BSW_NC_SEP = 8;
export const ENTITY_DE_M_NC_SEP = 19;
export const ENTITY_DE_BSW_RC_SEP = 14;
export const ENTITY_DE_M_RC_SEP = 22;

export const ENTITY_UT_BSW_NC_SEP = 15;
export const ENTITY_UT_M_NC_SEP = 21;
export const ENTITY_UT_BSW_RC_SEP = 16;
export const ENTITY_UT_M_RC_SEP = 23;

export const ENTITY_WI_M_SEP = 61;
export const ENTITY_WI_BSW_SEP = 62;
export const ENTITY_ID_BSW_SEP = 65;
export const ENTITY_ID_M_SEP = 66;

export const ENTITY_MO_BSW_SEP = 67;
export const ENTITY_MO_M_SEP = 68;

export const ENTITY_TX_BAS_BSW_SEP = 55;
export const ENTITY_TX_BAS_M_SEP = 56;
export const ENTITY_TX_BAS_BSW_2_SEP = 81;

export const ENTITY_TX_NCP_M_SEP = 57;
export const ENTITY_TX_NCP_BSW_SEP = 58;
export const ENTITY_TX_NCP_BSW_2_SEP = 82;

export const ENTITY_TX_BAS2_M = 36;
export const ENTITY_TX_BAS2_NM = 35;
export const ENTITY_TX_NCP2_M = 34;
export const ENTITY_TX_NCP2_NM = 33;
export const ENTITY_TX_NCP_M = 30;
export const ENTITY_TX_NCP_NM = 29;
export const ENTITY_TX_BASTION_M_NC = 26;
export const ENTITY_TX_BASTION_BSW_NC = 11;
export const ENTITY_TX_CSO_FLEXPAY = 2;
export const ENTITY_TX_JAG_INSTALL = 7;

export const ENTITY_CFF_CBW_M = 107;
export const ENTITY_CFF_CBW_NM = 108;
export const ENTITY_CFF_FEB_M = 109;
export const ENTITY_CFF_FEB_NM = 110;


// State Specific Values
export const SC_DRAW_VALUE_THRESHOLD = 610;

// Hotbox Decision
export const HOTBOX_ACCEPT = "A";
export const HOTBOX_CONTINUE = "C";
export const HOTBOX_REJECT = "R";
export const HOTBOX_KYC_SUCCESS = "SUCCESS";

//These number represents the maximum number each segment in the external progress bar
export const SHORT_EXTERNAL_MAX_ACCOUNT_CREATION_STEP = 2;
export const SHORT_EXTERNAL_MAX_DOC_SIGNATURE_STEP = 6;

// Reject reasons
export const REJECT_KYC = "KYC_REJECTED";
export const REJECT_UNSUPPORTED_STATE = "UNSUPPORTED_STATE";
export const REJECT_APPLICATION = "APPLICATION_REJECTED";
export const REJCT_FLEX_LOAN = "FLEX_LOAN_REJECTED";

// Consent document types
export const CONSENT_PRIVACY_POLICY = "Privacy Policy";
export const BANK_PRIVACY_POLICY = "Bank Privacy Policy";
export const PARTNER_PRIVACY_POLICY = "Partner Privacy Policy";
export const TERMS_OF_USE = "Terms of Use";
export const ECONSENT = "EConsent";
export const JURY_TRIAL = "Jury Trial";
export const DELAWARE_CONSENT = "Delaware Consent";
export const TERMS_SMS = "SMS Terms and Conditions";
export const AUTO_DIALER_TERMS = "Auto Dialers";
export const TERMS_AND_CONDITIONS = "Terms and Conditions";
export const SDR_DOCUMENT = "SDR Document";
export const SDR_DOCUMENT_POPUP = "SDR Document Popup";

// Consent document name
export const WELCOME_POPUP = "Welcome Pop-up";
export const CF_JURY_TRIAL = "CF Jury Trial";
export const CFF_JURY_TRIAL = "CFF Jury Trial";
export const CFF_FEB_JURY_TRIAL = "CFF FEB Jury Trial";
export const CF_ECONSENT = "CF EConsent";
export const CF_CONSENT_PRIVACY_POLICY = "CF Privacy Policy";
export const CF_TERMS_OF_USE = "CF Terms of Use";
export const TLS_PRIVACY_POLICY = "TLS Privacy Policy";

export const CF_BANK_PRIVACY_POLICY = "CBW Privacy Policy";
export const CCF_BANK_PRIVACY_POLICY = "CC Flow Privacy Policy";
export const FEB_BANK_PRIVACY_POLICY = "FEB Privacy Policy";

export const SDR_CONSENT = "SDR Consent";
export const SDR_CONSENT_POPUP = "SDR Consent Popup";

// Correction doc name
export const CORRECTION_DOC = "Loan Agreement (TILA) - Update";
export const LOC_LOAN_LIMIT_INCREASE_DOC = "Line of Credit Agreement (TILA) – Update";
export const LOC_LOWER_FEES_DOCUMENT = "Line of Credit Agreement (TILA) - Update";
export const BILLING_CYCLE_CHARGE_DOCUMENT = "Billing Cycle Charge Table - Update";
export const LOC_AMENDMENT = "LINE OF CREDIT AGREEMENT - AMENDMENT";



// Loan Document Subject Names
export const LOAN_DOC_SUBJECT_ACH_AUTH = "Voluntary ACH Authorization";
export const LOAN_DOC_SUBJECT_CUSTOMER_INFO_KEYWORD = "Customer Information";
export const LOAN_DOC_SUBJECT_TERMS_CONDITION_KEYWORD = "Terms and Conditions";
export const LOAN_DOC_SUBJECT_TRUTH_IN_LENDING_KEYWORD = "Truth in Lending";
export const LOAN_DOC_SUBJECT_ACH_AUTH_KEYWORD = "ACH Auth";
export const LOAN_DOC_SUBJECT_ARBITRATION_KEYWORD = "Arbitration";
export const LOAN_DOC_SUBJECT_CREDIT_SERVICE_DISCLOSURE_KEYWORD = "Credit Services Disclosure";
export const LOAN_DOC_SUBJECT_CREDIT_SERVICES_AGREEMENT_KEYWORD = "Credit Services";
export const LOAN_DOC_SUBJECT_PRIVACY_POLICY_KEYWORD = "Privacy";
export const LOAN_DOC_SUBJECT_LOAN_AGREEMENT_KEYWORD = "Loan Agreement";
export const LOAN_DOC_SUBJECT_LINE_OF_CREDIT_AGREEMENT_KEYWORD = "Line of Credit Agreement";
export const LOAN_DOC_SUBJECT_LINE_OF_CREDIT_AGREEMENT_SCHEDULE_AB = "Line of Credit Agreement - Schedules A & B";
export const LOAN_DOC_SUBJECT_PAD_AUTHORIZATION = "PAD Authorization";
export const LOAN_DOC_SUBJECT_DISCLOSURE_STATEMENT = "Disclosure Statement";


export const LOAN_DOC_SUBJECT_CUSTOMER_INFO_SHORTFORM = "CI";
export const LOAN_DOC_SUBJECT_TERMS_CONDITION_SHORTFORM = "TC";
export const LOAN_DOC_SUBJECT_TRUTH_IN_LENDING_SHORTFORM = "TILA";
export const LOAN_DOC_SUBJECT_ACH_AUTH_SHORTFORM = "ACH";
export const LOAN_DOC_SUBJECT_ARBITRATION_SHORTFORM = "ARB";
export const LOAN_DOC_SUBJECT_CREDIT_SERVICE_DISCLOSURE_SHORTFORM = "CSD";
export const LOAN_DOC_SUBJECT_CREDIT_SERVICES_AGREEMENT_SHORTFORM = "CSA";
export const LOAN_DOC_SUBJECT_PRIVACY_POLICY_SHORTFORM = "PP";
export const LOAN_DOC_SUBJECT_LOAN_AGREEMENT_SHORTFORM = "LA";
export const LOAN_DOC_SUBJECT_LINE_OF_CREDIT_AGREEMENT_SHORTFORM = "LOCA";
export const LOAN_DOC_SUBJECT_LINE_OF_CREDIT_AGREEMENT_SCHEDULE_AB_SHORTFORM = "LOCA_SAB";
export const LOAN_DOC_SUBJECT_PAD_AUTHORIZATION_SHORTFORM = "PAD_Auth";
export const LOAN_DOC_SUBJECT_DISCLOSURE_STATEMENT_SHORTFORM = "Disclosure_Stmt";
export const  PRODUCT_TYPE_INSTALLMENT="installment";

// Production URLs
export const PROFILE_PRODUCTION = "production"; // This name is passed on during the build command.

// To do item names
export const TODO_NAME_BANK_VERIFICATION = "Bank Verification";
export const TODO_NAME_DIRECT_MAIL_BANK_VERIFICATION = "Bank Verification - Direct Mail";
export const TODO_NAME_EMPLOYMENT_VERIFICATION = "Employment Verification Info";
export const TODO_NAME_REFERENCES = "References";
export const TODO_NAME_ESIG = "e-sig";
export const TODO_SPOUSAL_INFORMATION = "Spouse Info";
export const TODO_EMAIL_VERIFICATION = "Email Verification";
export const TODO_NAME_VERIFY_PAIDOFF_PRIOR_LOANS = "Verify Paid Off/Prior Loans";
export const TODO_NAME_VERIFY_PREVIOUS_LOAN_PAID_OFF = "Verify Previous Loan Paid Off";
export const TODO_NAME_COLLECT_PAYMENT_CARD_INFO = "Collect payment card info";
export const TODO_NAME_ACCOUNT_NUMBER = "Account Number";
export const TODO_NAME_ACCOUNT_OWNERSHIP = "Account Ownership";

// Bank Verification items api response
export const VERIFIED = "Verified";
export const NOT_VERIFIED = "Not_Verified";
export const DL_ERROR = "DL_Error";
export const BANK_VERIFICATION_POLLING_DELAY = 30000;
// organizations
export const MONEYKEY_ORGANIZATION_ID = 1;
export const CREDITFRESH_ORGANIZATION_ID = 2;
export const MONEYKEY_PLUS_ORGANIZATION_ID = 3;
export const CREDITFRESH_PLUS_ORGANIZATION_ID = 4;
export const FORA_ORGANIZATION_ID = 5;
export const PATHWARD_ORGANIZATION_ID = 6;
export const KOHO_ORGANIZATION_ID = 7;
export const BRAND_CREDITFRESH = "CF";
export const BRAND_MONEYKEY = "MK";

//Consent pop up tool tips
export const CONSENT_TOOL_TIP = "econsent";
export const LOCKED_CONSENT_TOOL_TIP = "lockedEconsent";
export const BANK_PRIVACY_POLICY_TOOL_TIP = "bankprivacypolicy";
export const ARBITRATION_TOOL_TIP = "arbitration";
export const PRIVACY_POLICY_TOOL_TIP = "privacypolicy";
export const TERMS_OF_USE_TOOL_TIP = "termsofuse";
export const AUTO_DIALER_TOOL_TIP = "autodialier";
export const PAYMENT_SCHEDULE_TOOL_TIP = "difference";
export const DECISION_LOGIC_TOOL_TIP = "decisionlogicfaq";
export const FLINKS_TOOL_TIP = "flinksfaq";
export const FLEX_LOAN_PLAN = "flexloanplan";
export const TEXAS_INSTALLMENT_LOAN_FEATURE = "texasinstallmentloanfeature";
export const APPLICATION_CODE_TOOL_TIP = "applicationCodeToolTip";
export const LINE_OF_CREDIT_TIP = "lineofcredit";
export const DIRECT_MAIL_TIP = "directmail";
export const OFFER_PAGE_TOOL_TIP = "OfferPagePopup";
export const INSTALLMENT_LOANS_TIP = "installmentloans";
export const BANK_VERIFICATION_ACCOUNT_ERROR_TIP = "bankVerificationAccountErrorTip";
export const BANK_VERIFICATION_COMPLETE_TIP = "bankVerificationCompleteTip";
export const BANK_VERIFICATION_INCOMPLETE_TIP = "bankVerificationIncompleteTip";
export const BANK_VERIFICATION_IN_PROGRESS_TIP = "bankVerificationInProgressTip";
export const BANK_VERIFICATION_TECHNICAL_ERROR_TIP = "bankVerificationTechnicalErrorTip";
export const ADDITIONAL_VERIFICATION_REQUIRED_TIP = "additionalVerificationRequiredTip";
export const LOAN_DOC_ACH_AUTHORIZATION_TIP = "loanDocACHAuthorizationTip";

//DM related constants
export const DM_INVALID_SSN = "Invalid SSN";
export const DM_EXPIRED_OFFER_CODE = "Expired Offer code";
export const DM_INVALID_OFFER_CODE = "Invalid Offer Code";

//Message Types
export const MESSAGE_TYPE_ERROR = "error";
export const MESSAGE_TYPE_SUCCESS = "success";

//KeyCodes
export const ARROW_KEY_LEFT = "ArrowLeft";
export const ARROW_KEY_RIGHT = "ArrowRight";
export const ENTER_KEY = "Enter";
export const TAB_KEY = "Tab";

export const TRUST_EV_URL = "https://cdn-us.trustev.com/trustev.min.js";
export const TRUST_EV_PUB_KEY = "d689b40ae2414efd9ad7a096ae0c8c86";

// Credit fresh max limit
export const CREDIT_FRESH_MAX_LIMIT = "$5,000";

// LMS Codes
export const LMS_JAGLMS = "JAGLMS";
export const LMS_TDC = "TDC";

// Request Status codes
export const REQUEST_SUCCESS = 200;

// Payment Schedule Item Payment Status
export const CANCELLED = "cancelled";
export const CLEARED = "cleared";
export const SCHEDULED = "scheduled";

// Flow names
const NC = "NC";
const RC = "RC";
const RAL = "RAL";

export const APPFLOWNAME = {
    NC,
    RC,
    RAL
};

const OTP_TYPE_EMAIL = "email";
const OTP_TYPE_PHONE = "phone";
// OTP
export const OTP_PROCESSDATA_TYPE = {
    OTP_TYPE_EMAIL,
    OTP_TYPE_PHONE
};

const OTP_STATUS_NEW = "NEW";
const OTP_STATUS_SENT = "SENT";
const OTP_STATUS_VERIFIED = "VERIFIED";
const OTP_STATUS_EXPIRED = "EXPIRED";
export const OTP_STATUS = {
    OTP_STATUS_NEW,
    OTP_STATUS_SENT,
    OTP_STATUS_VERIFIED,
    OTP_STATUS_EXPIRED
};

const PHONE_VERIFICATION_STATUS_SUCCESS = "SUCCESS";
const PHONE_VERIFICATION_STATUS_INVALID_CODE = "INVALID_CODE";
const PHONE_VERIFICATION_STATUS_CODE_EXPIRED = "CODE_EXPIRED";
const PHONE_VERIFICATION_STATUS_TOO_MANY_ATTEMPTS = "MAX_LIMIT_REACHED";
const PHONE_VERIFICATION_STATUS_SMS_TIME_LIMIT = "SMS_TIME_LIMIT";
const PHONE_VERIFICATION_STATUS_SMS_LIMIT_REACHED = "SMS_LIMIT_REACHED";
const PHONE_VERIFICATION_STATUS_SENT = "SENT";
const PHONE_VERIFICATION_STATUS_SENT_VIA_CALL = "SENT_VIA_CALL";
export const PHONE_VERIFICATION_STATUS_MESSAGE = {
    PHONE_VERIFICATION_STATUS_SUCCESS,
    PHONE_VERIFICATION_STATUS_INVALID_CODE,
    PHONE_VERIFICATION_STATUS_CODE_EXPIRED,
    PHONE_VERIFICATION_STATUS_TOO_MANY_ATTEMPTS,
    PHONE_VERIFICATION_STATUS_SMS_TIME_LIMIT,
    PHONE_VERIFICATION_STATUS_SMS_LIMIT_REACHED,
    PHONE_VERIFICATION_STATUS_SENT,
    PHONE_VERIFICATION_STATUS_SENT_VIA_CALL
};

const EMAIL_VERIFICATION_STATUS_SUCCESS = "SUCCESS";
const EMAIL_VERIFICATION_STATUS_INVALID_CODE = "INVALID_CODE";
const EMAIL_VERIFICATION_STATUS_CODE_EXPIRED = "CODE_EXPIRED";
const EMAIL_VERIFICATION_STATUS_TOO_MANY_ATTEMPTS = "MAX_LIMIT_REACHED";
const EMAIL_VERIFICATION_STATUS_SMS_TIME_LIMIT = "SMS_TIME_LIMIT";
const EMAIL_VERIFICATION_STATUS_OTP_LIMIT_REACHED = "OTP_LIMIT_REACHED";
const EMAIL_VERIFICATION_STATUS_SENT = "SENT";

export const EMAIL_VERIFICATION_STATUS_MESSAGE = {
    EMAIL_VERIFICATION_STATUS_SUCCESS,
    EMAIL_VERIFICATION_STATUS_INVALID_CODE,
    EMAIL_VERIFICATION_STATUS_CODE_EXPIRED,
    EMAIL_VERIFICATION_STATUS_TOO_MANY_ATTEMPTS,
    EMAIL_VERIFICATION_STATUS_SMS_TIME_LIMIT,
    EMAIL_VERIFICATION_STATUS_OTP_LIMIT_REACHED,
    EMAIL_VERIFICATION_STATUS_SENT,
};

export const OTP_EMAIL_VERIFICATION_EARLY_RESEND = "early_resend";

const PAYROLL_RESULT_SUCCESS = "success";
const PAYROLL_RESULT_FAIL = "fail";
const PAYROLL_RESULT_EXCEPTION = "exception";
const PAYROLL_RESULT_CONFIRMATION_NEEDED = "confirmation_needed";
export const PAYROLL_RESULT = {
    PAYROLL_RESULT_SUCCESS,
    PAYROLL_RESULT_FAIL,
    PAYROLL_RESULT_EXCEPTION,
    PAYROLL_RESULT_CONFIRMATION_NEEDED
};

// Bank Verification Systems
export const BV_SYSTEM = {
    DECISION_LOGIC: 1,
    FLINKS: 2,
    DIRECT_IMPORT: 3,
};

export const BANK_VERIFICATION_STATUS_CODE = {
    STARTED: 1,
    OAUTH: 4,
    SUCCESS: 20,
    FAILURE: -1,
    BANK_ERROR: -2
};

export const LOAN_CANCELLATION_TYPE_DENIED = "Denied";
export const LOAN_CANCELLATION_EXTENDED_STATUS_UNABLE_TO_VERIFY_ACCOUNT = "Unable to Verify Account Information";

// REPAY
export const REPAY_ORIGIN_URLS = {
    TEST: "https://propelholdings.sandbox.repay.io",
    TEST_PATHWARD: "https://pathward.sandbox.repay.io",
    TEST_CFF_CBW: "https://creditfresh.sandbox.repay.io",
    CFF_CBW: "https://creditfresh.repay.io",
    PATHWARD_PRODUCTION: "https://pathward.repay.io",
    PRODUCTION: "https://propelholdings.repay.io",
    FORA_PRODUCTION: "https://fora.repay.io",
    TEST_FEB_LAAS: "https://creditfreshforwardfeb.sandbox.repay.io",
    FEB_LAAS_PRODUCTION: "https://creditfreshfeb.repay.io"
}
export const REPAY_PAYMENT_SUCCESS_MESSAGE = "PAYMENT_SUCCESS";
export const LPP_PAYMENT_SUCCESS_MESSAGE = "transaction-success";
export const REPAY_PAYMENT_ERROR_MESSAGE = "PAYMENT_ERROR";
export const PAYMENT_UNSUCCESSFUL = "#unsuccessful";

// Identity-Verification constants
export const IDENTITY_VERIFICATION_TODO_POLLING_DELAY = 30000; // 30 seconds
export const IDENTITY_VERIFICATION_TODO_POLLING_MAX_DURATION_IN_MINUTES = 10;
export const IDENTITY_VERIFICATION_CALLBACK_EVENT_ON_COMPLETE = "on_complete";
export const IDENTITY_VERIFICATION_CALLBACK_EVENT_ON_ERROR = "on_error";
export const IDENTITY_VERIFICATION_CALLBACK_EVENT_ON_USER_EXIT = "on_user_exit";

// One Signal Production AppIds
export const MONEYKEY_ORGANIZATION_APP_ID = "75e4fdb0-8b9d-4c64-b73d-97c93ad1aa27";
export const MONEYKEY_PLUS_ORGANIZATION_APP_ID = "83500c28-0f14-4d51-8a42-c63ef9cb6cf7";
export const CREDITFRESH_ORGANIZATION_APP_ID = "7cdf2b70-4e0d-401a-8a77-af25b7331197";
export const CREDITFRESH_PLUS_ORGANIZATION_APP_ID = "26abca9d-f8f4-47b2-a084-8ae98b906a11";
export const FORA_ORGANIZATION_APP_ID = "8a529f1b-b199-4f2c-971f-5f8d2db7830b";
export const PATHWARD_ORGANIZATION_APP_ID = "e25b9dca-5f46-46f1-b09d-e66c8ab7e040";
export const FORA_PLUS_ORGANIZATION_APP_ID = "8a529f1b-b199-4f2c-971f-5f8d2db7830b"; //TODO Update this

//MFA
export const MFA_SYSTEM_MESSAGE_ENABLED = "System settings updated. Two-factor authentication enabled.";
export const MFA_SYSTEM_MESSAGE_DISABLED = "System settings updated. Two-factor authentication disabled.";

// QA Testing AppIds

export const ONESIGNANL_QA_APPID = {
    1: "dbcb392c-1838-47eb-89ed-c1c68277849c", //MoneyKey
    2: "524b20a5-5218-4cab-b1cb-98e98e16034f", // Credit Fresh
    3: "656b6983-2517-4f95-8258-e8ec147f2947", // MoneyKey Plus
    4: "c40f4d22-481c-44a7-b96a-c941d56e4385", // Credit Fresh Plus
    5: "e8a71f48-1291-40fa-9928-6c909af99f7a", // Fora Credit
    6: "b5dda321-b120-4729-8c41-d36ab7f26146"//Path Ward
}

export const FACEBOOK_ADS="facebook";
export const GOOGLE_ADS="google_ads";

export const CREDIT_EVOLUTION_TABLE_ONE = "1";
export const CREDIT_EVOLUTION_TABLE_TWO = "2";
export const CREDIT_EVOLUTION_TABLE_THREE = "3";
export const CREDIT_EVOLUTION_TABLE_FOUR = "4";
export const CREDIT_EVOLUTION_TABLE_SIX = "6";
export const PROTECTION_PLAN_ACKNOWLEDGE = "Acknowledged";
export const PROTECTION_PLAN_DECLINE = "Declined";

export const INSURANCE_ACTIVE = "Active";
export const INSURANCE_INACTIVE = "Inactive";
export const INSURANCE_CANCELLED = "Cancelled";

// Standalone Ach constants
export const ACH_ON = "ON";
export const ACH_OFF = "OFF";
export const ACH_PAD_AUTH = "ACH_PAD_AUTH";

export const LOAN_AMOUNT = {
    5: {
        MIN_AMOUNT: 1000,
        MAX_AMOUNT: 15000
    }
}

//Experian
export const EXPERIAN_CAMPAIGN_NAME = {
    CF: "#CF-CREDITMATCH-SF#",
    FEB: "#CF-FEB-CREDITMATCH-SF#",
    CCFLow: "#MKII-CREDITMATCH-SF#"
}

export const EXPERIAN_MONEYKEY_IMAGE_LINK = "https://cdn.moneykey.com/images/logo/experian-partner-logo.png";
export const EXPERIAN_CREDITFRESH_IMAGE_LINK = "https://cdn.creditfresh.com/img/logos/experian-partner-logo.png";
