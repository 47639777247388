// All form names

// Application
export const STATE_INFO = "stateInfo";
export const STATE_SELECTION = "stateSelection";
export const EMAIL_LOGIN = "emailLogin";
export const CREATE_PASSWORD = "createPassword";
export const ACCOUNT_TERMS = "accountTerms";
export const PERSONAL_INFO = "personalInfo";
export const VERIFY_PHONE = "verifyPhone";
export const ADDRESS_INFO = "addressInfo";
export const LIVING_SITUATION = "livingSituation";
export const PAYMENT_SCHEDULE = "paymentSchedule";
export const CREDIT_ESTIMATE = "creditEstimate";
export const EMPLOYMENT_INFO = "employmentInfo";
export const BANKING_INFO = "bankingInfo";
export const KNOW_YOUR_CUSTOMER = "knowYourCustomer";
export const LOAN_DOCS = "loandocs";
export const VERIFY_MFA = "verifyMfa";
//Reapply

export const RAL_PERSONAL_INFO = "ralPesonalInfo";
export const RAL_EMPLOYMENT_INFO = "ralEmploymentInfo";

// Auth
export const LOGIN = "login";
export const WELCOME = "welcome";
export const CREATE_ACCOUNT = "createAccount";
export const UPDATE_PASSWORD = "updatePassword";
export const RESET_PASSWORD = "resetPassword";
export const OPT_OUT = "optOut";
export const DEBT_VALIDATION = "debtValidation";

// Other
export const LOAN_AGREEMENTS = "loanAgreements";
export const APPLICATION_CODE = "applicationCode";
export const VERIFY_ACCOUNT = "verifyaccount";
export const PHONE_VERIFICATION = "phoneVerification";
export const DRAW_REQUEST = "drawRequest";
export const CANCEL_DRAW_REQUEST = "cancelDrawRequest";
export const RELOAN = "reloan";
export const OFFER_FORM = "offer";

export const ONLINE_PAYMENT_FORM = "onlinepayment";
export const CONFIRM_PAYMENT_FORM = "confirmPayment";

export const ACH_PAYMENT_FORM = "achpayment";
export const CONFIRM_ACH_PAYMENT_FORM = "confirmPayment";

export const WEBCHAT_FORM = "webchat";

export const FEEDBACK_FORM = "feedback";

export const SPOUSAL_INFO_FORM = "spousalInfo";

export const OTP_FORM = "otp";
export const OTP_LOGIN_FORM = "otpLogin";

// Statement selection form
export const STATEMENTS_FILTER_FORM = "statementsfilter";

export const DOCUMENT_UPLOAD_FORM = "documentUpload";
export const PAYMENT_PROTECTION_PLAN = "paymentProtectionPlan";
