import React from "react";
import {Link} from "react-router-dom";
import * as routeutility from "../utility/routeutility";
import * as route from "./route";

// [ GENERIC ]
export const GENERIC_ERROR_MESSAGE = "Something went wrong. Please try again";
export const OPTION_NOT_SELECTED = "Please select an option";
export const OPTIONS_NOT_SELECTED = "Please select your two pay dates";

export const EMAIL_ADDRESS__EMPTY = "Please enter your email address";
export const EMAIL_ADDRESS__INVALID = "Please enter a valid email address";
export const EMAIL_ADDRESS__INVALID_BRITE_VERIFY = "Please enter a valid email address";

export const FIRST_NAME__EMPTY = "Please enter your first name";
export const FIRST_NAME__LESS_THAN_2_CHARS = "Please enter your first name";

export const LAST_NAME__EMPTY = "Please enter your last name";
export const LAST_NAME__LESS_THAN_2_CHARS = "Please enter your last name";

export const BIRTHDATE__EMPTY = "Please enter your date of birth";
export const BIRTHDATE__INVALID = "Please enter your full date of birth";
export const BIRTHDATE__MONTH_INVALID = "Incorrect month entered";
export const BIRTHDATE__DAY_INVALID = "Incorrect day entered";
export const BIRTHDATE__YEAR_INVALID = "Incorrect year entered";
export const BIRTHDATE__MORE_THAN_120_YEARS = "Please update your date of birth";
export const BIRTHDATE__LESS_THAN_18_YEARS = "You must be at least 18 years old to apply";
export const BIRTHDATE__IS_IN_THE_FUTURE = "Please update your date of birth";
export const BIRTHDATE__LESS_THAN_19_YEARS = "You must be at least 19 years old to apply";

export const STATE__EMPTY = "Please choose a state";
export const STATE__EMPTY_CANADA = "Please enter a Province/Territory";
export const PROVINCE__EMPTY_CANADA = "Please choose a Province/Territory";
export const STATE__INVALID = "Please choose a state";

export const RESIDENCY__EMPTY = "Please choose a Citizenship/Residency Status";

export const SSN__EMPTY = "Please enter your SSN";
export const SSN__INVALID = "Please enter your valid 9 digit SSN";
export const SIN__INVALID = "Please enter your valid 9 digit SIN";

export const LAST_FOUR_SSN__INVALID = "Please enter the last 4 digits of your SSN";
export const LAST_FOUR_SSN__EMPTY = "Please enter the last 4 digits of your SSN";
export const INITIALS__EMPTY = "Please enter the initials of your first and last name";

export const PASSWORD__EMPTY = "Please enter a password";
export const PASSWORD__NOT_VALID = "Please enter a valid password";
export const PASSWORD__MISSING_A_LETTER = "Please add a letter";
export const PASSWORD__MISSING_A_CAPITAL_LETTER = "Please add a capital letter";
export const PASSWORD__MISSING_A_NUMBER = "Please add a number";
export const PASSWORD__MISSING_A_SPECIAL_CHARACTER = "Please add a special character";
export const PASSWORD__LENGTH_LESS_THAN_REQUIRED = "Your password must be at least 8 characters";
export const PASSWORD__DONT_MATCH = "Passwords must match";
export const PASSWORD_DO_NOT_MATCH = "Passwords do not match";

export const ECONSENT__CHECKBOX_NOT_MARKED = "Please check this box";
export const ECONSENT__CHECKBOX_NOT_MARKED_PATHWARD = "Required field. Please review and acknowledge the agreement.";
export const DELAWARE_CONSENT__CHECKBOX_NOT_MARKED = "Please check this box";
export const SDR_CONSENT__CHECKBOX_NOT_MARKED = "Please check this box";
export const SDR_CONSENT__CONTENT_NOT_LOADED = "Something went wrong. Please reload page";
export const FEEDBACK_LOAN_NUMBER__HAS_NUMBERS_ONLY = "Loan Number can contain numbers only";
export const FEEDBACK_CONTENT__EMPTY = "Please enter your feedback";
export const FEEDBACK_HTML_TAG_NOT_ALLOWED = "< and > tags are not allowed.";
export const CHECKBOX_NOT_MARKED = "Please check all boxes";

export const ENABLE_POP_UP = "A popup blocker may be preventing the application from opening the page. If you have a popup blocker, try disabling it to open the window.";

// File Upload
export const FILE_NOT_SELECTED = "Please select a file to upload.";
export const FILE_INVALID = "File cannot be uploaded. File format is incorrect.";
export const FILE_INVALID_CHARACTERS = "File cannot be uploaded. File name contains invalid characters.";
export const FILE_INVALID_NAME_SIZE_MIN = "File cannot be uploaded. File name must contain at least 3 characters.";
export const FILE_INVALID_NAME_SIZE_MAX = "File cannot be uploaded. File name cannot exceed 100 characters.";
export const FILE_OVER_MAX_SIZE = "File cannot be uploaded. Maximum file size of 5MB exceeded.";
export const OTHER_FIELD_INVALID = "Please enter a valid document description.";

export const NOT_ELIGIBLE_FOR_ACH_SCHEDULING = "Scheduled payments are no longer available. Please make a payment with your debit or prepaid card.";
export const SELECTED_ACH_DATE_INVALID = "Scheduled payments are no longer available for the date selected. Please choose a different date.";
// '---------------------[ APPLICATION]----------------------'
// '---------------------[ stateinfo page ]----------------------'
export const STATEINFO_EMAIL_ADDRESS__IS_NOT_UNIQUE = email => (
    <span>
        This email is already associated with an account.{" "}
        <Link to={routeutility.getRedirectPagewithEmailQueryString(email, route.STATE_LOGIN)}>Log in here</Link>.
    </span>
);

// '---------------------[ accountterms page ]----------------------'
export const ACCOUNTTERMS_EXISTING_FLEX_LOAN__NOT_SELECTED = OPTION_NOT_SELECTED;

// '--------------------[ personalinfo page ]--------------------'
export const PERSONALINFO_SSN__IS_NOT_UNIQUE = function(maskedemail, supportNumber) {
    return (
        <span>
            This SSN is associated with a different account: {maskedemail}. Please <a href="/">log in</a> or
            <a href={"tel:" + supportNumber}> contact us</a> to recover your account.
        </span>
    );
};
export const PERSONALINFO_PHONE_NUMBER__EMPTY = "Please enter your phone number";
export const PERSONALINFO_PHONE_NUMBER__INVALID = "Please enter a valid phone number";
export const PERSONALINFO_IS_MOBILE__NOT_SELECTED = OPTION_NOT_SELECTED;
export const PERSONALINFO_RECEIVE_OFFERS__NOT_SELECTED = OPTION_NOT_SELECTED;

// MoneyKey specific error messages:
export const PERSONALINFO_DM_CODE__EMPTY = "Please enter your application code";
export const PERSONALINFO_DM_CODE__INVALID = "Please provide a valid application code";
export const PERSONAL_INFO_DM_CODE_EXPIRED = "The application code provided is no longer valid";
export const PERSONAL_INFO_DM_CODE_INVALID_SSN = "Please ensure the information entered is correct and try again.";

//Pathward Specific error messages:
export const PATHWARD_PERSONALINFO_DM_CODE__EMPTY = "Please enter your offer code";
export const PATHWARD_PERSONALINFO_DM_CODE__INVALID = "This offer code is not valid";
export const PATHWARD_PERSONAL_INFO_DM_CODE_EXPIRED = "This offer code is not valid";
export const PATHWARD_PERSONAL_INFO_OFFER_CODE_INVALID_SSN =
    "Please ensure the information entered is correct and try again.";

// Creditfresh specific error messages:
export const CF_PERSONALINFO_DM_CODE_EXPIRED = "Offer code provided is no longer valid";
export const CF_PERSONALINFO_DM_CODE__INVALID = "Offer code provided is invalid";

export const OFFER_PAGE_DM_CODE__INVALID = "Offer code provided is invalid";
export const OFFER_PAGE_DM_CODE_EXPIRED = "Offer code provided is no longer valid";

export const RELOAN_PAGE_OFFER_CODE__EMPTY = "Please enter your promo code";
export const RELOAN_PAGE_OFFER_CODE__INVALID = "Please enter a valid promo code";
export const RELOAN_PAGE_OFFER_CODE__EXPIRED = "The promo code entered is no longer valid";

// '--------------------[ addressinfo page ]---------------------'
export const ADDRESSINFO_STREET_ADDRESS__EMPTY = "Please enter your street address";
export const ADDRESSINFO_STREET_ADDRESS__INVALID_LENGTH = "Your street address cannot be less than 5 characters";
export const ADDRESSINFO_STREET_ADDRESS__DOES_NOT_START_WITH_NUMBER = "Street address should start with a number";
export const ADDRESSINFO_STREET_ADDRESS__HAS_NUMBERS_ONLY = "Street address shouldn't only have numbers";
export const ADDRESSINFO_UNIT__SPECIAL_CHARACTERS = "Unit/Apartment number cannot contain only special characters";
export const ADDRESSINFO_CITY__EMPTY = "Please enter your city";
export const ADDRESSINFO_CITY__HAS_NUMBERS = "City name cannot contain numbers";
export const ADDRESSINFO_CITY__SPECIAL_CHARACTERS = "City name cannot contain only special characters";
export const ADDRESSINFO_ZIPCODE__EMPTY = "Please enter your zip code";
export const ADDRESSINFO_ZIPCODE__INVALID_LENGTH = "Zip code should be 5 digits";
export const ADDRESSINFO_ZIPCODE__INVALID_CHARACTERS = "Zip code must only contain numbers";
export const ADDRESSINFO_ZIPCODE__ALLZEROS = "Zip code cannot be all zeros";
export const ADDRESSINFO_POSTAL_CODE__EMPTY = "Please enter your Postal code";
export const ADDRESSINFO_POSTAL_CODE__INVALID_LENGTH = "Postal code should be 6 characters";
export const ADDRESSINFO_POSTAL_CODE__INVALID_CHARACTERS = "Please enter valid postal code";
export const ADDRESSINFO_PO_BOX = "Address cannot be a PO Box."

// '------------------[ livingsituation page ]-------------------'
export const LIVINGSITUATION_RENT_OWN__NOT_SELECTED = OPTION_NOT_SELECTED;
export const LIVINGSITUATION_YEARS__NOT_SELECTED = OPTION_NOT_SELECTED;

// '------------------[ document upload page ]-------------------'
export const DOCUMENT_UPLOAD_TYPE__NOT_SELECTED = OPTION_NOT_SELECTED;
export const DOCUMENT_UPLOAD_DESCRIPTION__NOT_SELECTED = OPTION_NOT_SELECTED;
export const DOCUMENT_UPLOAD__FILE_NOT_SELECTED = FILE_NOT_SELECTED;
export const DOCUMENT_UPLOAD__FILE_INVALID = FILE_INVALID;
export const DOCUMENT_UPLOAD__FILE_INVALID_CHARACTERS = FILE_INVALID_CHARACTERS;
export const DOCUMENT_UPLOAD__FILE_INVALID_FILE_NAME_SIZE_MIN = FILE_INVALID_NAME_SIZE_MIN;
export const DOCUMENT_UPLOAD__FILE_INVALID_FILE_NAME_SIZE_MAX = FILE_INVALID_NAME_SIZE_MAX;
export const DOCUMENT_UPLOAD__FILE_OVER_MAX_SIZE = FILE_OVER_MAX_SIZE;
export const DOCUMENT_UPLOAD__OTHER_FIELD_INVALID = OTHER_FIELD_INVALID;

// '------------------[ paymentschedule page ]-------------------'
export const PAYMENTSCHEDULE_PAYCHECK_AMOUNT__EMPTY = "Please enter the amount";
export const PAYMENTSCHEDULE_RENT_AMOUNT__EMPTY = "Please enter the amount";

// '-------------------[ employmentinfo page ]-------------------'
export const EMPLOYMENTINFO_COMPANY_NAME__EMPTY = "Please enter your employer name";
export const EMPLOYMENTINFO_COMPANY_NAME__INVALID = "Please enter your employer name";
export const EMPLOYMENTINFO_COMPANY_NAME__WORD_MISSING = "Employer name must contain letters";
export const EMPLOYMENTINFO_BENEFITS_TYPE__NOT_SELECTED = "Please specify the benefit type";
export const EMPLOYMENTINFO_INCOME_TYPE__NOT_SELECTED = "Please specify income type";

// '--------------------[ bankinginfo page ]---------------------'
export const BANKINGINFO_ROUTING_NUMBER__EMPTY = "Please enter your bank's ABA/routing number";
export const BANKINGINFO_ROUTING_NUMBER__INVALID = "Please enter a 9 digit ABA/routing number";
export const BANKINGINFO_ROUTING_NUMBER__ALLZEROS = "Bank's ABA/routing number cannot be all zeros";
export const BANKINGINFO_ROUTING_NUMBER__INVALID_CHECKSUM = "Please enter a valid ABA/routing number";
export const BANKINGINFO_ACCOUNT_NUMBER__EMPTY = "Please enter your account number";
export const FORA_BANKINGINFO_ACCOUNT_NUMBER__EMPTY = "Please enter your Account number";
export const BANKINGINFO_ACCOUNT_NUMBER__INVALID = "Account number cannot be less than 5 digits";
export const BANKINGINFO__ACCOUNT_NUMBER_FORA__INVALID = "Account number cannot be less than 7 digits";
export const BANKINGINFO_ACCOUNT_NUMBER__ALLZEROS = "Account number cannot be all zeros";
export const BANKINGINFO_BANK_NAME__EMPTY = "Please enter bank name";
export const FORA_BANKINGINFO_BANK_NAME__EMPTY = "Please enter Bank name";
export const BANKINGINFO_BANK_NAME__INVALID_SHORT = "Bank name cannot be less than 2 characters";
export const BANKINGINFO_BANK_NAME__INVALID_LONG = "Bank name cannot be longer than 50 characters";
export const BANKINGINFO_BANK_NAME__INVALID_NO_CHAR = "Bank name must contain letter characters";
export const BANKINGINFO_INSTITUTION_NUMBER_EMPTY = "Please enter Institution number";
export const BANKINGINFO_INSTITUTION_NUMBER_INVALID = "Please enter a 3 digit Institution number";
export const BANKINGINFO_INSTITUTION_NUMBER_ALLZEROS = "Institution number's cannot be all zeros";
export const BANKINGINFO_BRANCH_TRANSIT_NUMBER_EMPTY = "Please enter Branch transit number";
export const BANKINGINFO_BRANCH_TRANSIT_NUMBER_INVALID = "Please enter a 5 digit Branch transit number";
export const BANKINGINFO_BRANCH_TRANSIT_NUMBER_ALLZEROS = "Branch Transit number's cannot be all zeros";

// '-----------------------[ resetpassword page ]------------------------'

export const RESETPASSWORD_EMAIL_ADDRESS__DOES_NOT_EXIST = function(supportNumber, csName) {
    return (
        <span>
            We cannot find an account associated with this email address. Please check that your email is correct or
            contact {csName} at <a href={"tel:" + supportNumber}>{supportNumber}</a>.
        </span>
    );
};
export const RESETPASSWORD_SUBMIT_ERROR =
    "An error occured while trying to send the reset link. Please try again later.";
// '---------------------------[ login page ]----------------------------'
export const LOGIN_PASSWORD__EMPTY = "Please enter your password";

// '---------------------------[ LoanAgreements page ]----------------------------'
export const LOANAGREEMENTS_SSN__NO_MATCH_ON_LAST_4_NUMBERS =
    "The last 4 digits of SSN you entered does not match with our record";
export const LOANAGREEMENTS_INITIALS__NO_MATCH = "The initials you entered do not match with our records";

export const RAL_LOAN_AMOUNT__EMPTY = "Please enter required loan amount";
export const RAL_LOAN_AMOUNT_ONLY_NUMBERS = "Loans amount field can only have numbers";
export const RAL_LOAN_AMOUNT_GREATER = "Requested loan amount is greater than possible maximum loan amount.";
export const RAL_LOAN_AMOUNT_LESSER = "Requested loan amount is lesser than possible minimum loan amount.";

// '---------------------------[ Online Payment page ]-----------------------------'

export const INVALID_CARD_NUMBER = "Please enter a valid card number";
export const INVALID_FULL_NAME = "Please enter the cardholder’s name";
export const INVALID_CVV_NUMBER = "Please enter a valid security code";
export const INVALID_ZIP_CODE = "Please enter a valid ZIP Code";
export const INVALID_PAYMENT_AMOUNT = "Please enter a payment amount";
export const INVALID_MIN_PAYMENT_AMOUNT =
    "The amount you entered is below the minimum. Please enter an amount greater than or equal to $5.00";
export const INVALID_EXPIRY_DATE = "Please enter a valid expiration date";
export const INVALID_MAX_PAYMENT_AMOUNT = "Payment amount cannot exceed your outstanding balance";
export const VALID_PAYMENT_AMOUNT_LESS_THAN_DUE =
    "As the Payment Amount is less than the Scheduled Payment Due, you will still have a payment due as indicated above.";
export const VALID_PAYMENT_LESS_THAN_SUM_OF_PAYMENT_DUE_AND_AMOUNT_PAST_DUE =
    "The Payment Amount will be applied to your Amount Past Due and your Current Balance. You will still have a Scheduled Payment Due as indicated above.";
export const NO_DROPDOWN_OPTION_SELECTED = "Please select a payment amount";
export const SCHEDULE_ACH_PAYMENT_NOT_SELECTED = "Please select schedule ach payment or amount past due.";

// '---------------------------[ Spousal Info Action Item ]-----------------------------'

export const SPOUSE_FIRST_NAME__EMPTY = "Please enter your spouse's first name";
export const SPOUSE_FIRST_NAME__LESS_THAN_2_CHARS = "Please enter your spouse's first name";

export const SPOUSE_LAST_NAME__EMPTY = "Please enter your spouse's last name";
export const SPOUSE_LAST_NAME__LESS_THAN_2_CHARS = "Please enter your spouse's last name";

export const SPOUSE_EMAIL_ADDRESS__EMPTY = "Please enter your spouse’s email address OR provide an alternate address";
export const SPOUSE_EMAIL_ADDRESS__INVALID = "Please enter a valid email address OR provide an alternate address";

export const SPOUSE_CITY__EMPTY = "Please enter a city name";
export const SPOUSE_CITY__HAS_NUMBER = "City name cannot contain numbers";
export const SPOUSE_CITY__SPECIAL_CHARACTERS = "City name cannot contain only special characters";

export const SPOUSE_STREET_ADDRESS__EMPTY = "Please enter a street address";
export const SPOUSE_STREET_ADDRESS__INVALID_LENGTH = "Your street address cannot be less than 5 characters";
export const SPOUSE_STREET_ADDRESS__DOES_NOT_START_WITH_NUMBER = "Street address should start with a number";
export const SPOUSE_STREET_ADDRESS__HAS_NUMBERS_ONLY = "Street address shouldn't only have numbers";

export const SPOUSE_STREET_ADDRESS_2__SPECIAL_CHARACTERS =
    "Unit/Apartment number cannot contain only special characters";

export const SPOUSE_INVALID_ZIP_CODE = "Please enter ZIP code";

export const MARITAL_STATUS_NOT_SELECTED = "Please select your Marital Status";

export const SPOUSE_STATE__EMPTY = "Please enter a state";
export const SPOUSE_STATE__INVALID = "Please enter a state";

export const OTP_VERIFICATION_CODE_EMPTY = "Please enter a code";
export const OTP_VERIFICATION_CODE_INVALID_LENGTH = "Your code cannot be less than 6 characters";
export const OTP_VERIFICATION_CODE_INVALID_CHARACTERS = "Code must only contain numbers";
export const OTP_VERIFICATION_CODE_INVALID_CODE_DASHBOARD = "Verification code invalid.";
export const OTP_VERIFICATION_CODE_INVALID_CODE = "Verification code invalid. Please try again.";
export const AUTHENTICATION_CODE_INVALID_CODE = "Authentication code invalid";
export const AUTHENTICATION_CODE_ALREADY_SENT = "Authentication code already sent. Please try again in 1 minute.";
export const OTP_VERIFICATION_CODE_ALREADY_SENT = "Verification code already sent. Please try again in 1 minute.";
export const PHONE_VERIFICATION_CODE_INVALID_CODE = "Verification code invalid. Please try again.";

// Error Message
export const LMS_AUTO_APPROVAL_EFFECTIVE_DATE_MISMATCH =
    "Cannot submit for approval because effective date is equal or before current date!";
export const IGNORE_TOAST_POPUP_ERROR_MESSAGE_LIST = [LMS_AUTO_APPROVAL_EFFECTIVE_DATE_MISMATCH];

// Statements Form Filter page errors
export const STATEMENT_FILTERS_DATE_SELECTION_MISSING = "Please select date range";
export const STATEMENT_FILTERS_FROM_DATE_AFTER_TO_DATE = "From date is after to date";
export const STATEMENT_FILTERS_OPTIONS_NOT_SELECTED = "Please select one of the statement filter options";
export const MFA_INCORRECT_PASSWORD_ERR_CODE = "The password entered is incorrect. Please try again.";
export const PAYMENT_PROTECTION_PLAN_ACKNOWLEDGEMENT = "Please select yes to enroll in payment protection plan";
