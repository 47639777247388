import React from "react";
import * as Loan from "../constant/loan";
import _ from "lodash";
import * as constant from "../constant/constant";

export function fragmentizeString(templateString, obj) {
    if (!templateString) {
        return <React.Fragment />;
    }

    let parts = templateString.split(/({{\d*\?.*?}})/g);

    for (let i = 1; i < parts.length; i += 2) {
        //grab additional data from template
        const dataMatcher = /{{(\d*)\?(.*)}}/;
        const dataCapture = dataMatcher.exec(parts[i]);

        //build the component
        let componentBuilder = obj;
        if (dataCapture[1]) {
            componentBuilder = componentBuilder[dataCapture[1]];
        }
        if (dataCapture[2]) {
            componentBuilder = componentBuilder(dataCapture[2]);
        }

        // Create a clone of componentBuilder to change its key if it is a react element
        if (React.isValidElement(componentBuilder)) {
            let componentBuilderClone = {};
            Object.assign(componentBuilderClone, componentBuilder);
            componentBuilderClone.key = `x-${i}`;
            parts[i] = componentBuilderClone;
        } else {
            //assign the component in place of template holder
            parts[i] = componentBuilder;
        }
    }

    return <React.Fragment>{parts}</React.Fragment>;
}

//function to set the page title
export function setBrowserTitle(title) {
    if (!document.getElementsByTagName("title")[0]) {
        let createTitleTag = document.createElement("title");
        document.head.appendChild(createTitleTag);
    }

    document.getElementsByTagName("title")[0].text = title;
}

//format a phone number to (xxx) xxx-xxxx
export function formatPhoneNumber(phoneNumberString) {
    let cleaned = ("" + phoneNumberString).replace(/\D/g, "");
    let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
        return `(${match[1]}) ${match[2]}-${match[3]}`;
    }
    return phoneNumberString;
}

/**
 * Recursively selects and reassigns values in {base} with the corresponding value from {override}.
 * If a value exists in {override} that does not exist in {base}, then add it to the return object.
 *
 * @param {object} base
 * @param {object} override
 *
 * @return {object}
 */
const overrideContent = (base = {}, override = {}) => {
    return _.toPairs(override).reduce((agg, [key, value]) => {
        if (!agg[key] || typeof value === "string") {
            return {...agg, [key]: value};
        } else {
            return {...agg, [key]: overrideContent(agg[key], value)};
        }
    }, base);
};

/**
 * @param {Object} level
 * @param {Array.<String>} [nextLevelKey, ...restOfKeys]
 */
const getContentTree = (level, [nextLevelKey, ...restOfKeys]) => {
    if (level) {
        if (level.content) {
            if (level.nextLevel && level.nextLevel[nextLevelKey]) {
                return overrideContent(level.content, getContentTree(level.nextLevel[nextLevelKey], restOfKeys));
            } else {
                return level.content;
            }
        } else {
            if (level.nextLevel && level.nextLevel[nextLevelKey]) {
                return getContentTree(level.nextLevel[nextLevelKey], restOfKeys);
            }
        }
    }
    return {};
};

export const getContentTreeMemoizer = function(content) {
    var memo = {};

    return function (keys) {
        let memoKey = keys.filter(_.isString).join(".");
        let prevValue = memo[memoKey];
        if (prevValue) {
            return prevValue;
        } else {
            let newVlaue = getContentTree(content, keys);
            memo[memoKey] = newVlaue;
            return newVlaue;
        }
    }
}

const organizationContent = JSON.parse(document.getElementById("organizationContent").value);

const memoized_getContentTree = getContentTreeMemoizer(organizationContent);

export const getContent = (...keys) => memoized_getContentTree(keys);

//Used to map database product types to front end product types
export const mapLoanTypes = loanType => {
    if (loanType === Loan.LoanType.INSTALLMENT_LOAN) {
        return Loan.CONTENT_LOAN_TYPE_INSTALLMENT;
    } else if (loanType === Loan.LoanType.LINE_OF_CREDIT) {
        return Loan.CONTENT_LOAN_TYPE_LOC;
    } else if(loanType === Loan.LoanType.PAYDAY) {
        return Loan.CONTENT_LOAN_TYPE_PAYDAY;
    }
};

export const mapProductTypeToName = productType => {
    switch (productType) {
        case Loan.LoanType.INSTALLMENT_LOAN:
            return "Installment";
        case Loan.LoanType.LINE_OF_CREDIT:
            return "LOC";
        case Loan.LoanType.PAYDAY:
            return "Payday";
        default:
            return "";
    }
};

/**
 * Function that returns the appropriate Rates and Terms url depending on state
 * @param state
 * @returns {string}
 */
export const getRatesAndTermsPage = state => {
    switch (state){
        case constant.STATE_TENNESSEE:
            return `${constant.PUBLIC_WEBSITE}/line-of-credit-loans-online/tennessee/`;
        case constant.STATE_KANSAS:
            return `${constant.PUBLIC_WEBSITE}/line-of-credit-loans-online/kansas/`;
        case constant.STATE_NEW_MEXICO:
            return `${constant.PUBLIC_WEBSITE}/line-of-credit-loans-online/new-mexico/`;
        case constant.STATE_MISSOURI:
            return `${constant.PUBLIC_WEBSITE}/line-of-credit-loans-online/missouri/`;
        default:
            return `${constant.PUBLIC_WEBSITE}`;
    }
};

/**
 * Function that returns cdn url for example cheque image
 * @param orgId
 * @returns {string}
 */
export const getChequeExampleUrl = (orgId) => {
    switch (orgId) {
        case constant.MONEYKEY_ORGANIZATION_ID: return "https://cdn.moneykey.com/images/svg/Cheque-Example-MK.svg";
        case constant.CREDITFRESH_ORGANIZATION_ID:
        case constant.CREDITFRESH_PLUS_ORGANIZATION_ID:
            return "https://cdn.creditfresh.com/img/svg/Cheque-Example-CBW-FEB.svg";
        case constant.MONEYKEY_PLUS_ORGANIZATION_ID: return "https://cdn.moneykey.com/images/svg/Cheque-Example-CCFlow.svg";
        case constant.FORA_ORGANIZATION_ID: return "https://cdn.foracredit.ca/images/svg/Cheque-Example-Fora-KOHO.svg";
        case constant.PATHWARD_ORGANIZATION_ID: return "https://cdn.loc.pathward.com/web/images/Cheque-Example-PW.svg";
        case constant.KOHO_ORGANIZATION_ID: return "https://cdn.foracredit.ca/images/svg/Cheque-Example-Fora-KOHO.svg";
        default: return "https://cdn.moneykey.com/images/svg/Cheque-Example-MK.svg";
    }
}
