import {destroy} from "redux-form";
import {put} from "redux-saga/effects";

import * as form from "../../shared/constant/form";

export function* destroyApplicationForms() {
    try {
        yield put(destroy(form.STATE_INFO));
        yield put(destroy(form.CREATE_PASSWORD));
        yield put(destroy(form.ACCOUNT_TERMS));
        yield put(destroy(form.PERSONAL_INFO));
        yield put(destroy(form.ADDRESS_INFO));
        yield put(destroy(form.LIVING_SITUATION));
        yield put(destroy(form.PAYMENT_SCHEDULE));
        yield put(destroy(form.CREDIT_ESTIMATE));
        yield put(destroy(form.EMPLOYMENT_INFO));
        yield put(destroy(form.BANKING_INFO));
        yield put(destroy(form.KNOW_YOUR_CUSTOMER));
        yield put(destroy(form.LOAN_DOCS));
    } catch (err) {
        // todo
    }
}
