import {CONTENT as field} from "../../shared/constant/field";
import * as actionTypes from "../action/actionTypes";
import _ from "lodash";
import * as constant from "../../shared/constant/constant";

const initialState = {
    [field.CONSENT]: {
        [field.PRIVACY_POLICY]: {},
        [field.TERMS_OF_USE]: {},
        [field.ECONSENT]: {},
        [field.JURY_TRIAL]: {},
        [field.TERMS_SMS]: {},
        [field.BANK_PRIVACY_POLICY]: {},
        [field.AUTO_DIALER_TERMS]: {},
        [field.SDR_CONSENT]: {},
        [field.SDR_CONSENT_POPUP]: {}
    }
};

function clearContent() {
    return {
        [field.CONSENT]: {
            [field.PRIVACY_POLICY]: {},
            [field.TERMS_OF_USE]: {},
            [field.ECONSENT]: {},
            [field.JURY_TRIAL]: {},
            [field.TERMS_SMS]: {},
            [field.BANK_PRIVACY_POLICY]: {},
            [field.AUTO_DIALER_TERMS]: {},
            [field.SDR_CONSENT]: {},
            [field.SDR_CONSENT_POPUP]: {}
        }
    };
}

const getConsentDocumentSuccess = (state, action) => {
    let consentDocuments = action.payload.response.data;
    let consentDocumentChanges = {};
    _.forEach(consentDocuments, function(document) {
        switch (document.type.trim()) {
            case constant.CONSENT_PRIVACY_POLICY:
                consentDocumentChanges[field.PRIVACY_POLICY] = document;
                break;
            case constant.TERMS_OF_USE:
                consentDocumentChanges[field.TERMS_OF_USE] = document;
                break;
            case constant.ECONSENT:
                consentDocumentChanges[field.ECONSENT] = document;
                break;
            case constant.JURY_TRIAL:
                consentDocumentChanges[field.JURY_TRIAL] = document;
                break;
            case constant.TERMS_SMS:
                consentDocumentChanges[field.TERMS_SMS] = document;
                break;
            case constant.BANK_PRIVACY_POLICY:
                consentDocumentChanges[field.BANK_PRIVACY_POLICY] = document;
                break;
            case constant.AUTO_DIALER_TERMS:
                consentDocumentChanges[field.AUTO_DIALER_TERMS] = document;
                break;
            case constant.TERMS_AND_CONDITIONS:
                consentDocumentChanges[field.TERMS_AND_CONDITIONS] = document;
                break;
            case constant.SDR_DOCUMENT:
                consentDocumentChanges[field.SDR_CONSENT] = document;
                break;
            case constant.SDR_DOCUMENT_POPUP:
                consentDocumentChanges[field.SDR_CONSENT_POPUP] = document;
                break;
            default:
                throw new Error("No Consent Document Found");
        }
    });

    return {
        ...state,
        [field.CONSENT]: {
            ...state[field.CONSENT],
            ...consentDocumentChanges
        }
    };
};

const contentReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_CONSENT_DOCUMENT_SUCCESS:
        case actionTypes.GET_CONTENT_BY_DOC_NAME_SUCCESS:
            return getConsentDocumentSuccess(state, action);

        case actionTypes.CLEAR_CONTENT: {
            return clearContent();
        }
        default:
            return state;
    }
};

export default contentReducer;
