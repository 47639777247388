// Reducer field constants

// ====== RootReducer Constants Start ======
// Root reducer tree combines individual reducer
// Then root reducer is being passed to the createStore
export const REDUCER_NODE_AUTH = "auth";
export const REDUCER_NODE_APPLICATION = "application";
export const REDUCER_NODE_ERROR = "error";
export const REDUCER_NODE_FORM_REDUCER = "FormReducer";
export const REDUCER_NODE_DASHBOARD = "dashboard";
export const REDUCER_NODE_LOAN = "loan";
export const REDUCER_NODE_INSURANCE = "insurance";
export const REDUCER_NODE_FORM = "form";
export const REDUCER_NODE_APP_PROGRESS = "applicationProgress";
export const REDUCER_NODE_CONTENT = "content";
export const REDUCER_NODE_ROUTER = "router"; // router is created by react-router library.
export const REDUCER_NODE_ORGANIZATION = "organization";
export const REDUCER_NODE_MC_IDENTITY = "mcIdentity";
export const REDUCER_NODE_ACH = "ach";

// ====== RootReducer Constants Ends ======

// ====== AuthReducer Constants Starts ======
export const USERAUTH = "userauth";
export const EMAIL = "email";
export const PASSWORD = "pwd";
export const ISAUTHENTICATED = "isauthenticated";
export const MKWEBAUTHTOKEN = "mkwebauthtoken";
export const DIDPASSWORDRESET = "didpasswordreset";
export const OVERRIDE_TIERKEY = "overrideTierKey";
export const USERID = "userId";
export const USERAPPINFOID = "userAppInfoId";
export const CUSTOMER_ID = "customer_id";
export const CA_CUSTOMER_ID = "caCustomerId";
export const LMS_CODE = "lmsCode";
export const SWITCHED_ORG = "switchedOrg";
export const ISMKWEBRESETPASSWORDTOKENVALID = "ismkwebresetpasswordtokenvalid";
export const ISEMAILVERIFICATIONTOKENVALID = "isemailverificationtokenvalid";
export const ISEMAILSENT = "isEmailSent";
export const ISEMAILVERIFIED = "isemailverified";
export const AUTHREQUESTTIMESTAMP = "authRequestTimeStamp";
export const LAST_VISITED_TIME = "lastVisitedTime";
export const IS_PASSWORD_TEMPORARY = "isPasswordTemporary";
export const MFA_CONTACT_RESPONSE = "mfaContactResponseDTO";
export const EMAIL_VERIFICATION_OTP_STATUS_MESSAGE = "emailVerificationOTPStatusMessage";
export const EMAIL_VERIFICATION_OTP_STATUS_MESSAGE_TIMESTAMP = "emailVerificationOTPStatusMessageTimestamp";
export const EMAIL_STATE_LOGIN_TIMESTAMP = "emailStateLoginTimestamp";
export const AUTH = {
    USERAUTH,
    EMAIL,
    PASSWORD,
    ISAUTHENTICATED,
    MKWEBAUTHTOKEN,
    USERID,
    SWITCHED_ORG,
    USERAPPINFOID,
    ISMKWEBRESETPASSWORDTOKENVALID,
    ISEMAILVERIFICATIONTOKENVALID,
    AUTHREQUESTTIMESTAMP,
    LAST_VISITED_TIME,
    IS_PASSWORD_TEMPORARY,
    EMAIL_VERIFICATION_OTP_STATUS_MESSAGE,
    EMAIL_VERIFICATION_OTP_STATUS_MESSAGE_TIMESTAMP
};
// ====== AuthReducer Constants Ends ======

// ====== Application Reducer Constants Start =====
export const USERAPPINFO = "userappinfo";
export const USERAPPMISC = "misc";
export const USERLOANAPPINFO = "userloanappinfo";
export const USER_LOGIN_INFO = "userlogininfo";
export const ASSOCIATED_ORG = "associatedorg";
export const UNIQUE = "unique";
// user_application_info -- table starts
export const ID = "id";
export const FIRSTNAME = "firstname";
export const LASTNAME = "lastname";
export const DOB = "dob";
export const SSN = "ssn";
export const PHONE_NUMBER = "phone_number";
export const IS_MOBILE = "is_mobile";
export const ADDRESS = "address";
export const ADDRESS2 = "address2";
export const CITY = "city";
export const STATE = "state";
export const ZIP = "zip";
export const RESIDENCY_STATUS = "residencyStatus";
export const PROMO_CODE = "promoCode";
export const DM_PROMO_CODE_AMOUNT = "loanAmount";
export const IS_RENTAL = "isRental";
export const YEARS = "years";
export const INCOME_TYPE = "incomeType";
export const PAYMENT_TYPE = "payType";
export const EMPLOYER_NAME = "employer";
export const PAY_FREQUENCY = "payFrequency";
export const LAST_PAY_DATE = "lastPayDate";
export const NEXT_PAY_DATE = "nextPayDate";
export const WOM = "wom";
export const PAYCHECK_AMOUNT = "paycheckAmount";
export const RENT_AMOUNT = "rentAmount";
export const GROSS_MONTHLY = "grossmonthly";
export const NMI = "nmi";
export const ROUTING_NUMBER = "routingNumber";
export const BANK_NAME = "bankName";
export const ACCOUNT_NUMBER = "accountNumber";
export const ACCOUNT_TYPE = "accountType";
export const BANK_INSTITUTION_CODE = "bankInstitutionCode";
export const BANK_TRANSIT_CODE = "bankTransitCode";
export const AMOUNT = "amount";
export const IS_RECEIVE_OFFERS = "receiveOffers";
export const APPCODE = "appCode";
export const EXISTING_FLEX_LOAN = "existingFlexLoan";
export const CHOICES = "choices";
export const ISPRODUCTCHANGEPOPUPSHOWN = "isProductChangePopupShown";
export const HAS_NEW_FEES = "hasNewFees";
export const PAGE_INFORMATION = "pageInformation";
// user_application_info -- table ends

// misc -- starts
export const CUSTOMERID = "customerId";
export const ISRAL = "isRAL";
export const ISRC = "isRC";
export const IS_XRAL = "isXRal";
export const TIERKEY = "tierKey";
export const IS_ACTIVE_ACCOUNT = "isActiveAccount";
export const LOANID = "loanId";
export const LOAN_AMOUNT = "loanAmount";
export const IS_SHOW_APPCODE = "isShowAppCode";
export const IS_ORGANIC = "isOrganic";
export const IS_SHORTFORM = "isShortform";
export const DUPLICATE_ORGANIZATION= "duplicateOrganization";
export const CLICK_ID = "clickid";
export const IS_PHONE_VERIFIED = "isPhoneVerified";
export const IS_PHONE_VERIFICATION_SKIPPED = "isPhoneVerificationSkipped";
export const PHONE_VERIFICATION_HAS_MOBILE = "isPhoneVerificationHasMobile";
export const CONTACT_INFO_IS_MOBILE = "contactInfoIsMobile";
export const PHONE_VERIFICATION_STATUS_MESSAGE = "phoneVerificationStatusMessage";
export const PHONE_VERIFICATION_SUBMITTED = "phoneVerificationSubmitted";
export const EMAIL_VERIFICATION_OTP_IS_VERIFIED = "emailVerificationOTPIsVerified";

export const EMAIL_VERIFICATION_HAS_EMAIL = "isEmailVerificationHasEmail";
export const IS_EMAIL_VERIFIED = "isEmailVerified";
export const IS_EMAIL_VERIFICATION_SKIPPED = "isEmailVerificationSkipped";
export const INITIAL_EMAIL_VERIFICATION_SENT = "initialEmailVerificationSent";
export const EMAIL_VERIFICATION_SUBMITTED = "emailVerificationSubmitted";
export const EMAIL_VERIFICATION_STATUS_MESSAGE = "emailVerificationStatusMessage";

export const EXTERNAL_REFERENCE_ID = "externalReferenceId";
export const HB_PRE_AND_POST_STAGE_FAIL = "hbPreAndPostStageFail";

// Sub id tracking
export const SUB_ID_TRACKING = "subIdTracking";
export const SUB_ID = "subId";
export const SUB_ID2 = "subId2";
export const SUB_ID3 = "subId3";
export const CLICK_ID_1 = "clickId";
export const AD_ID = "adId";
export const UTM_CAMPAIGN = "utmCampaign";
export const MERCHANT_ID = "merchantId";
export const RAN_SITE_ID = "ranSiteId";
export const TIERKEY_MATCHES_ORGANIZATION = "tierKeyMatchesOrganization";
export const SUB_ID_LANDING_TIME = "subIdLandingTime";
export const CREATE_TIMESTAMP = "createTimestamp";
export const UPDATE_TIMESTAMP = "updateTimestamp";
export const GCLID = "gclId";
export const FBC = "fbc";
export const FBP = "fbp";
export const CQ_SRC = "cq_src";
export const ONE_SIGNAL_USERS = "oneSignalUsers";
export const PLAYR_ID = "playerId";

// progress -- starts
export const ISPHONEVERIFICATIONPAGESUBMITTED = "isPhoneVerificationPageSubmitted";
export const ISEMPLOYMENTINFOPAGESUBMITTED = "isEmploymentInfoPageSubmitted";
export const ISPAYMENTSCHEDULEPAGESUBMITTED = "isPaymentSchedulePageSubmitted";
export const ISBANKINGINFOPAGESUBMITTED = "isBankingInfoPageSubmitted";
export const ISPREAPPROVEDPAGESUBMITTED = "isPreApprovedPageSubmitted";
export const USERREQUESTEDAMOUNT = "userRequestedAmount";
export const ORIGINATEDUNIQUEURLVISITED = "originatedUniqueUrlVisited"

export const PRESTAGEDECISION = "preStageDecision";
export const POSTSTAGEDECISION = "postStageDecision";
export const ISKYC = "isKYC";
export const ISTERMSAGREED = "isTermsAgreed";
export const ISPASSWORDTEMPORARY = "isPasswordTemporary";
export const ISCONTACTUPDATED = "isContactUpdated";
export const DM_TIERKEY_MESSAGE = "dmTierKeyMessage";
export const IS_PROMOCODE_VALID = "isPromoCodeValid";
export const APPLY_LINK_CLICKED = "applyLinkCliked";
export const PROMO_CODE_ORG_ID = "promoCodeOrgId";
export const OFFER_PAGE_VALIDATED = "offerPageValidated";
export const DM_CALCULATION_MODEL = "dmCalculationModel";
export const IS_CREDITFRESH_SUPPORTED = "isCreditfreshSupportedState";
export const CREDITFRESH_LIMIT = "creditFreshLimit";
//export const ORGANIZATION_ID = "organizationId";

//Loan Limits
export const MINAMOUNT = "minAmount";
export const MAXAMOUNT = "maxAmount";

// misc -- ends

// KYC Questions Constant Start
export const KYC = "kyc";
export const KYC_QUESTIONS = "kycQuestions";
export const KYC_ID = "id";
export const KYC_HEADER_ID = "kycHeaderId";
export const KYC_QUESTION_ID = "questionId";
export const KYC_QUESTION_ORDER = "questionOrder";
export const KYC_QUESTION_TYPE = "questionType";
export const KYC_DISPLAY = "display";

export const KYC_ANSWER_LIST = "answerList";
export const KYC_ANSWER_ID = "id";
export const KYC_ANSWER_CHOICE_ID = "choiceId";
export const KYC_ANSWER_CHOICE_ORDER = "choiceOrder";
export const KYC_ANSWER_DISPLAY = "display";
export const KYC_ANSWER_QUESTION_ID = "questionId";

export const KYC_REMAINING_SECONDS = "kycRemainingSeconds";
export const KYC_SUBMISSION_RESPONSE = "kycSubmissionResponse";
export const KYC_REAPPLY_TIME = "kycReapplyTime";
export const IS_KYC_COMPLETED = "isKYCCompleted";
// KYC Questions Constant End

// Opt out Constants starts
export const OPTOUT = "privacyOptOut";
export const OPTOUT_ID = "id";
export const OPTOUT_CUSTOMER_ID = "customerId";
export const OPTOUT_BASE_LOAN_ID = "baseLoanId";
export const OPTOUT_AFFILIATE_BUSINESS_PURPOSE = "affBusinessPurposeOptOut";
export const OPTOUT_AFFILIATE_MARKETING = "affMarketingOptOut";
export const OPTOUT_NONAFFILIATE_MARKETING = "nonAffMarketingOptOut";
export const OPTOUT_CREATE_TIMESTAMP = "createTimeStamp";
// Opt out Constants ends
// ====== Application Reducer Constants End =====

// ====== Error Reducer Constants Start =====
export const API = "api";
export const UI = "ui";
export const ERROR_START_PERSONALINFO = "errorStartPersonalInfo";
export const ERROR_START_ADDRESSINFO = "errorStartAddressInfo";
export const ERROR_START_LIVINGSITUATION = "errorLivingSituation";
export const ERROR_START_EMPLOYMENTINFO = "errorEmploymentInfo";
export const ERROR_START_PAYMENTSCHEDULE = "errorPaymentSchedule";
export const ERROR_START_BANKINGINFO = "errorBankingInfo";
export const ERROR_UPDATE_PASSWORD = "errorUpdatePassword";
export const ERROR_START_PRESTAGEHBCHECK = "errorPreStageHBCheck";
export const ERROR_ISVALIDMKWEBRESETPASSWORDTOKEN = "errorIsValidResetPasswordToken";
export const ERROR_VERIFY_EMAIL = "errorVerifyEmail";
export const ERROR_START_CREATEACCOUNT = "errorCreateAccount";
export const ERROR_START_VERIFYPHONE = "errorVerifyPhone";
export const ERROR_LOAN_LIMITS = "errorLoanLimits";
export const ERROR_LOAN_LIMITS_BY_ORG_AND_STATE = "errorLoanLimitsByOrgAndState";
export const ERROR_GET_ACTIVE_LOAN = "errorGetActiveLoan";
export const ERROR_GET_ACTIVE_LOAN_SUMMARY = "errorGetActiveLoanSummary";
export const ERROR_GET_LATEST_NON_WITHDRAWN_LOAN_SUMMARY = "errorGetLatestNonWithdrawnLoanSummary";
export const ERROR_LOAN_DOCS_BY_CUSTOMER = "errorLoanDocsByCustomer";
export const ERROR_LOAN_DOC_CONTENT_BY_DOCVERSIONID = "errorLoanDocContentByDocVersionId";
export const ERROR_SAVE_LOAN_AGREEMENT = "errorSaveLoanAgreement";
export const ERROR_GET_KYC_QUESTIONS = "errorGetKYCQuestions";
export const ERROR_SAVE_KYC_QA = "errorSaveKYCQA";
export const ERROR_SAVE_COMM_PREFS = "errorSaveCommPrefs";
export const ERROR_GET_EXTERNAL_CUSTOMER_APP_INFO = "errorGetExternalAppInfo";
export const ERROR_UPDATE_ACTIVE_LOAN_AMOUNT = "errorUpdateActiveLoanAmount";
export const ERROR_GET_LANDING_PAGE = "errorGetLandingPage";
export const ERROR_SAVE_OPTOUT_PREFERENCES = "errorSaveOptOutPreferences";
export const ERROR_GET_KYC_REAPPLY_TIME = "errorGetKYCReapplyTime";
export const ERROR_IS_CREDITFRESH_SUPPORTED = "errorIsCreditfreshSupported";
export const ERROR_GET_AGREEMENT_DETAILS = "errorGetAgreementDetails";
export const ERROR_GET_STATEMENT_DETAILS = "errorGetStatementDetails";
export const ERROR_GET_STATEMENT_PDF = "errorGetStatementPDF";
export const ERROR_GET_LOAN_AGREEMENT_PDF = "errorGetLoanAgreementPDF";
export const ERROR_IS_CUSTOMER_TX_IMPORT = "errorIsCustomerTXImport";
export const ERROR_LOGIN = "errorLogin";
export const ERROR_LOGIN_BLOCKED = "errorLoginBlocked";
export const ERROR_SAVE_CONSENT_DOCUMENT = "errorSaveConsentDocument";
export const ERROR_GET_ORGANIZATION_CONFIG = "errorOrganizationConfig";
export const ERROR_TRACKING_BEACON = "errorTrackingBeacon";
export const ERROR_GET_PAYMENT_SCHEDULE = "errorGetPaymentSchedule";
export const ERROR_GET_PAYMENT_INFO = "errorGetPaymentInfo";
export const ERROR_GET_PAYMENT_TRANSACTION_INFO = "errorGetPaymentTransactionInfo";
export const ERROR_GET_HISTORY = "errorGetHistory";
export const ERROR_RELOANHBCHECK = "errorReloanHBCheck";
export const ERROR_GET_JWTHB_TOKEN = "errorGetJWTHBToken";
export const ERROR_SAVE_EXTERNAL_LEAD_QUERY_PARAMS = "errorSaveExternalLeadQueryParams";
export const ERROR_REAPPLY_RAL_LOAN = "errorReapplyRALLoan";
export const ERROR_VALIDATE_DM_CODE = "errorValidateDMCode";
export const ERROR_CREDIT_LIMIT_INCREASE = "errorCreditLimitIncrease";
export const ERROR_IS_CARD_PAYMENT_ENABLED = "errorIsCardPaymentEnabled";
export const ERROR_CARD_PAYMENT_PREVIEW = "errorCardPaymentPreview";
export const ERROR_ONE_TIME_PAYMENT_URL = "errorOneTimePaymentUrl";
export const ERROR_CURRENT_STATEMENT_BALANCE = "errorCurrentStatementBalance";
export const ERROR_SUBMIT_PAYMENT = "errorSubmitPayment";
export const ERROR_TRUST_EV_SESSION = "errorTrustEvSession";
export const ERROR_STATE_SELECTION_ORGANIZTION = "stateSelectionOrganization";
export const ERROR_SUBMIT_SPOUSE_INFO = "errorSubmitSpouseInfo";
export const ERROR_TDC_REDIRECT_PAGE = "errorTDCRedirectPage";
export const ERROR_GET_CHAT_HOURS = "errorGetChatHours";
export const ERROR_IS_CHAT_ACTIVE = "errorIsChatActive";
export const ERROR_SUBMIT_FEEDBACK = "errorSubmitFeedback";
export const ERROR_SUBMIT_DEBT_VALIDATION = "errorSubmitDebtValidation";
export const ERROR_GET_MC_IDENTITY_SESSION_ID = "errorGetMcIdentitySessionId";
export const ERROR_POST_MC_IDENTITY_DATA = "errorPostMcIdentityData";
export const ERROR_SUBMIT_AUTO_APPROVAL = "errorSubmitAutoApproval";
export const ERROR_SAVE_SUB_ID_TRACKING = "errorSaveSubidTracking";
export const ERROR_GET_SUB_ID_TRACKING = "errorGetSubidTracking";
export const ERROR_VERIFY_EFFECTIVE_DATE = "errorVerifyEffectiveDate";
export const ERROR_REEXPRESS = "errorReexpress";
export const ERROR_START_PRODUCT_CHANGE_POPUP_ACKNOWLEDGEMENT = "errorStartProductChangePopupAcknowledgement";
export const ERROR_OTP_GET_STATUS = "errorOTPGetStatus";
export const ERROR_OTP_SEND_CODE = "errorOTPSendCode";
export const ERROR_OTP_VERIFY_CODE = "errorOTPVerifyCode";
export const ERROR_OTP_PROCESS_DATA = "errorOTPProcessData";
export const ERROR_PAYROLL_PROCESS_DATA = "errorPayrollProcessData";
export const ERROR_CONFIRM_PAYROLL_DATA = "errorConfirmPayrollData";
export const ERROR_CHECK_DRAW_ELIGIBILITY = "errorCheckDrawEligibility";
export const ERROR_SHOW_LOC_LOWER_FEE = "errorShowLocLowerFee";
export const ERROR_SAVE_LOC_LOWER_FEE_POPUP_ACKNOWLEDGEMENT = "errorSaveLocLowerFeePopupAcknowledgement";
export const ERROR_GET_LOC_LOWER_FEE_LEVEL = "errorGetLocLowerFeeLevel";
export const ERROR_STATE_ORGANIZATION_CONFIG = "errorStateOrganizationConfig";
export const ERROR_UPDATE_CUSTOMER_PAYROLL_AND_BANK_INFO = "errorUpdateCustomerPayrollAndBankInfo";
export const ERROR_SAVE_DOCUMENT_VIEWED_ON_DASHBOARD = "errorSaveDocumentViewedOnDashboard";
export const ERROR_GET_BANK_INFO = "errorGetBankInfo";
export const ERROR_KOHO_BV_DIRECT_IMPORT = "errorKohoBvDirectImport";
export const ERROR_DOCUMENT_UPLOAD = "errorDocumentUpload";
export const ERROR_DOCUMENT_UPLOAD_VIRUS = "errorDocumentUploadVirus";
export const ERROR_IS_DOCUMENT_UPLOAD_ENABLED = "errorIsDocumentUploadEnabled";
export const ERROR_IS_ACCOUNT_ON_HOLD = "errorIsAccountOnHold";
export const ERROR_CREATE_ONFIDO_SDK = "errorCreateOnfidoSdk";
export const ERROR_CREATE_ONFIDO_SDK_EVENT_TRACKING = "errorCreateOnfidoSdkEventTracking";
export const ERROR_RETRIEVE_ANNUAL_INTEREST_RATE = "errorRetrieveAnnualInterestRate";
// ====== Error Reducer Constants End =====

// ====== Dashboard Reducer Constants Start =====
const PAYMENT_REPAY = "paymentRepay";
const PERSONAL_INFO = "personalInfo";
const COMMUNICATION_PREFS = "communicationPreferences";
const COMM_TEXT_MESSAGES = "SMS_TRANSACTIONAL";
const COMM_PROMO_MESSAGES = "SMS_MARKETING";
const COMM_PROMO_CALLS = "PHONE_MARKETING";
const COMM_PROMO_EMAIL = "commPromoEmail";
const HOME_PHONE = "homePhone";
const CELL_PHONE = "cellPhone";
const LAST_VISITED = "lastVisited";
const VERIFIED = "verified";
const ACCOUNT = "account";
const STATEMENTS = "statements";
const HISTORY = "history";
const HISTORY_ITEMS = "loanItems";
const SELECTED_STATEMENT_CONTENT = "selectedStatementContent";
const AGREEMENTS = "agreements";
const SELECTED_AGREEMENT_CONTENT = "selectedAgreementContent";
const ACTION_ITEMS = "actionItems";
const SPOUSE_INFO = "spouseInfo";
const MARITAL_STATUS = "maritalStatus";
const SPOUSE_FIRST_NAME = "spouseFirstName";
const SPOUSE_LAST_NAME = "spouseLastName";
const SPOUSE_EMAIL = "spouseEmail";
const SPOUSE_ADDRESS_1 = "spouseAddress1";
const SPOUSE_ADDRESS_2 = "spouseAddress2";
const SPOUSE_CITY = "spouseCity";
const SPOUSE_STATE = "spouseState";
const SPOUSE_ZIP_CODE = "spouseZipCode";
const BANK_VERIFICATION_ITEMS = "bankVerificationItems";
const BANK_VERIFICATION_STATUS = "bankVerificationStatus";
const IS_BANK_VERIFICATION_CONFIRMED = "isBankVerificationConfirmed";
const SHOW_VERIFY_MY_ACCOUNT_BUTTON = "showVerifyMyAccountButton";
const DECISION_LOGIC_URL = "decisionLogicUrl";
const DECISION_LOGIC_STATUS = "decisionLogicStatus";
const BV_SYSTEM_ID = "bvSystemId";
const BV_SYSTEM_NAME = "bvSystemName";
const BV_SYSTEM_URL = "bvSystemUrl";
const MAXIMUM_RETRY = "maximumRetry";
const BV_REQUEST_ID = "bvRequestId";
const DECISION_LOGIC_DETAILED_STATUS_INFO = "decisionLogicDetailedStatusInfo";
const TODO_BANK_VERIFICATION = "todoBankVerification";
const TODO_ACCOUNT_NUMBER = "todoAccountNumber";
const TODO_ACCOUNT_OWNERSHIP = "todoAccountOwnership";
const TODO_IDENTITY_VERIFICATION = "Identity Verification";
const TODO_VERIFY_PAIDOFF_PRIOR_LOANS = "todoVerifyPaidoffPriorLoans";
const TODO_VERIFY_PREVIOUS_LOAN_PAIDOFF = "todoVerifyPreviousLoanPaidOff";
const TODO_EMAIL_VERIFICATION = "todoEmailVerification";
const TODO_ESIG = "todoESig";
const TODO_COLLECT_PAYMENT_INFO = "todoCollectPaymentInfo";
const TODO_KYC = "KYC";
const TODO_EMPLOYMENT_VERIFICATION = "todoEmploymentVerification";
const TODO_REFERENCES = "todoReferences";
const TODO_SPOUSAL_INFORMATION = "todoSpousalInformation";
const BANK_VERIFICATION = "bankVerification";
const EMPLOYMENT_VERIFICATION = "employmentVerification";
const REFERENCES = "references";
const NEXT_EFFECTIVE_DATE = "nextEffectiveDate";
const IS_TX_EPIC_IMPORT = "isTXEpicImport";
const LOC_OFFER_DETAILS = "locOfferDetails";
const OFFERED_INCREASE = "offeredIncrease";
const OFFERED_LIMIT = "offeredLimit";
const LIMIT_INCREASE_STATUS = "status";
const UNUSED_OFFER_LINK = "unusedOfferLink";
const ONLINE_PAYMENT_DETAILS = "onlinePaymentDetails";
const FULL_NAME = "fullName";
const PAYMENT_CARD_NUMBER = "paymentCardNumber";
const CARD_ZIP_CODE = "zip";
const CARD_CVV_NUMBER = "cvv";
const CARD_EXPIRY_DATE = "expiryDate";
const ONLINE_PAYMENT_AMOUNT = "paymentAmount";
const HIDE_MINIMUM_PAYMENT_OPTION = "hideMinimumPayment";
const DISABLE_ONLINE_PAYMENT_DROPDOWN = "disableOnlinePayment";
const TODO_FIELD_COMPLETIONTIME = "completionTime";
const TODO_FIELD_ID = "id";
const TODO_FIELD_NAME = "toDoName";
const TODO_FIELD_TYPE = "todoType";
const PAYMENT_ENABLED = "paymentEnabled";
const CARD_PAYMENT_ENABLED = "cardPaymentEnabled";
const IS_LPP_IFRAME_SUPPORTED = "isLppIframeSupported";
const PAYMENT_LPP = "paymentLpp";
const CARD_PAYMENT_ENABLED_STATUS = "status";
const CURRENT_BALANCE = "currentBalance";
const MINIMUM_PAYMENT_AMOUNT = "paymentAmount";
const PREVIOUS_MINIMUM_PAYMENT_AMOUNT = "previousPaymentAmount";
const PAST_DUE_AMOUNT = "pastDueAmount";
const PREVIOUS_PAST_DUE_AMOUNT = "previousPastDueAmount";
const CURRENT_BALANCE_AND_STATEMENT = "currentBalanceAndStatement";
const CARD_PAYMENT_DUE_DATE = "paymentDueDate";
const PREVIOUS_CARD_PAYMENT_DUE_DATE = "previousPaymentDueDate";
const CARD_PAYMENT_PREVIEW = "cardPaymentPreview";
const NOTIFICATION_MESSAGE = "notificationMessage";
const CARD_PAYMENT_FEES = "fees";
const CARD_PAYMENT_INTEREST = "interest";
const CARD_PAYMENT_OUTSTANDING_BALANCE = "outstandingBalance";
const CARD_PAYMENT_PAST_DUE = "pastDue";
const CARD_PAYMENT_PRINCIPAL = "principal";
const CARD_PAYMENT_DETAILS = "cardPaymentDetails";
const CARD_TRANSACTION_DETAILS = "cardTransaction";
const CARD_PAYMENT_AUTHORIZATION_CODE = "authorizationCode";
const CARD_PAYMENT_LAST_FOUR_DIGITS = "cardLastFour";
const CARD_PAYMENT_AMOUNT = "paymentAmount";
const CARD_PAYMENT_PROCESSING_DATE = "paymentProcessDate";
const CARD_PAYMENT_SUCCESS = "success";
const CARD_PAYMENT_TRANSACTION_ID = "transactionId";
const CARD_PAYMENT_LOAN_DATA = "loanData";
const CARD_PAYMENT_TRANSACTIONS = "transactions";
const CARD_TRANSACTION_DETAILS_INDEX_0 = "0";
const CARD_TRANSACTION_DETAILS_INDEX_1 = "1";
const SHOW_LOADER = "showLoader";
const SHOW_ERROR = "showError";
const FLINKS_LOGIN_SUCCESS = "flinksLoginSuccess";
const FLINKS_LOGIN_FAILURE = "flinksLoginFailure";
const REQUEST_DETAILS = "flinksVerification";
const REQUEST_DETAILS_STATUS = "status";
const BV_DATA_READY_RESULT = "isBVDataReady";
const BV_DATA_POLL_PARAMS = "pollParameters";
const DASHBOARD_PDF_DOCUMENT = "dashboardPDFDocument";
const SETTLEMENT_POPUP_CLOSED_FLAG = "settlementPopupClosedFlag";
//Ach Payment Info
const ACH_PAYMENT_INFO = "achPaymentInfo";
const ACH_PAYMENT_INFO_PAYMENT_TYPE = "paymentType";
const ACH_PAYMENT_INFO_PAYMENT_METHOD = "paymentMethod";
const ACH_PAYMENT_INFO_PAYMENT_STATUS = "paymentStatus";
const ACH_PAYMENT_INFO_OTHER = "other";
const ACH_PAYMENT_INFO_FEES = "fees";
const ACH_PAYMENT_INFO_INTEREST = "interest";
const ACH_PAYMENT_INFO_PRINCIPAL = "principal";
const ACH_PAYMENT_INFO_TOTAL = "total";
const ACH_PAYMENT_INFO_PAYMENT_SCHEDULED_DATE = "paymentScheduledDate";
const ACH_PAYMENT_INFO_BANK_NAME = "bankName";
const ACH_PAYMENT_INFO_ROUTING_NUMBER = "routingNumber";
const ACH_PAYMENT_INFO_ACCOUNT_TYPE = "accountType";
const ACH_PAYMENT_INFO_ACCOUNT_NUMBER_LAST_4 = "accountNumberLast4";
const ACH_PAYMENT_INFO_PAYMENT_PSI_ID = "psiId";

const CREDIT_PAYMENT_BATCH_SCHEDULE = "creditPaymentBatchSchedule";
const ACH_EFFECTIVE_DATE = "effectiveDate";
const IS_SAME_DAY_ACH = "isSameDayAch";
const BATCH_DATE = "batchDate";
const BATCH_TIME = "batchTime";
const LASTEST_SAME_DAY_ACH_TIME_ON_EFFECTIVE_DATE = "latestSameDayACHTimeOnEffectiveDate";
const DOCUMENT_UPLOAD_ENABLED = "documentUploadEnabled";
const IS_ACCOUNT_ON_HOLD = "isAccountOnHold";
const ONFIDO = "onfido";
const CREDIT_EVOLUTION_STATUS = "creditEvolutionStatus";
const CREDIT_EVOLUTION_APPLICABLE = "applicable";
const CREDIT_EVOLUTION_PRE_AUTH_PAYMENT = "preAuthPaymentStatus";
const CREDIT_EVOLUTION_REACHED_TOP_CHARGE_TABLE = "reachedTopChargeTable";
const CREDIT_EVOLUTION_ENROLLED = "enrolled";
const CURRENT_PRICING_MODEL = "currentPricingModel";
const ESTIMATED_EVOLUTION_DATE = "estimatedEvolutionDate";
const CURRENT_SAVING_PERCENTAGE = "currentSavingPercentage";
const CHARGE_TABLE = "chargeTables";
const MODEL_LABEL = "modelLabel";
const MODEL_VERSION = "modelVersion";
const BASE_RANGE = "baseADPBRange";
const BASE_CHARGE = "baseCharge";
const CURRENT_RANGE = "currentADPBRange";
const CURRENT_CHARGE = "currentCharge";
const CREDIT_EVOLUTION_DETAILS_RESPONSE = "creditEvolutionDetailsResponse";
const PRICING_MODEL_DETAILS = "pricingModelDetails";
const CURRENT_ENTITY = "currentEntity";
const MONTHLY_ENTITY = "monthlyEntityId";
const PRICING_MODEL_CHARGE_TABLE_ITEMS = "chargeTableItems";
const MONTHLY_CHARGE = "monthlyCharge";
const NON_MONTHLY_CHARGE = "nonMonthlyCharge";
const PRICING_MODEL_PRICE_RANGE = "priceRange";
const CEP_DAYS_IN_GOOD_STANDING = "daysInGoodStanding";
const CEP_EVOLUTION_START_DATE = "evolutionStartDate";
const CEP_LAST_ORIGINATED_DATE = "lastOriginatedDate";
const CEP_LAST_GRADUATED_DATE = "lastGraduatedDate";
// user otp info starts
export const USEROTPINFO = "userotpinfo";
export const ISOTPENABLED = "isEnabled";
export const ISOTPSENTPOSTLOGIN = "isOTPSentPostLogin";
export const PHONENUMBER = "phoneNumber";
export const OTPSTATUS = "otpStatus";
export const SMSSENTDATETIME = "smsSentDateTime";
export const SENDOTPCODECLICKEDTIME = "sendOTPCodeClickedTime";
export const OTP_ERROR_CODE = "errorCode";
export const OTP_ERROR_MESSAGE = "errorMessage";

// process OTP data Starts
const OTP_PROCESSDATA = "processOTPData";
const OTP_PROCESSDATA_RESULT = "result";
const OTP_PROCESSDATA_IS_AUTOFUND = "is_autofund";
const OTP_PROCESSDATA_IS_NBC = "is_nbc";

// process Payroll data starts
const PAYROLL_PROCESSDATA = "processPayrollData";
const PAYROLL_PROCESSDATA_RESULT = "result";
const PAYROLL_PROCESSDATA_IS_AUTOFUND = "is_autofund";
const PAYROLL_PROCESSDATA_IS_NBC = "is_nbc";
const PAYROLL_PROCESSDATA_PRIMARY_AMOUNT = "primary_amount";
const PAYROLL_PROCESSDATA_EMPLOYER_NAME = "employer_name";
const PAYROLL_PROCESSDATA_FULL_NMI = "full_net_monthly";
const PAYROLL_PROCESSDATA_PRIMARY_FREQUENCY = "primary_frequency";
const PAYROLL_PROCESSDATA_CREDIT_LIMIT = "credit_limit";
const PAYROLL_PROCESSDATA_PRIMARY_FREQUENCY_DETAILS = "primary_frequency_details";
const PAYROLL_PROCESSDATA_NEXT_PAY_DATE_COMPUTED = "next_pay_date_computed";
const PAYROLL_PROCESSDATA_BANK_NAME = "bank_name";
const PAYROLL_PROCESSDATA_ACCOUNT_TYPE = "account_type";
const PAYROLL_PROCESSDATA_ACCOUNT_NUMBER = "account_number";
const PAYROLL_PROCESSDATA_IS_REJECTED = "is_rejected";

// Draw Eligibility Info
const DRAW_ELIGIBILITY_INFO = "drawEligibilityInfo";
const DRAW_ELIGIBILITY_IS_BANK_ACCOUNT_INFO_UPDATED = "bankAccountInfoUpdated";
const DRAW_ELIGIBILITY_IS_ACTIVE = "active";
const DRAW_ELIGIBILITY_IS_ONLINE_DRAW_ENABLED = "onlineDrawEnabled";
const DRAW_DISABLE_DUE_TO_OVER_PAYMENT = "drawDisabledDueToOverPayment";

// Loc Lower Fees
const SHOW_LOC_LOWER_FEE_POPUP = "showLocLowerFeePopup";
const FEE_LEVEL = "feeLevel";

const BANKING_INFO = "bankingInfo";
const BANKING_INFO_BANK_NAME = "bankName";
const BANKING_INFO_ACCOUNT_NUMBER = "accountNumber";
const BANKING_INFO_ACCOUNT_TYPE = "accountType";
const BANKING_INFO_FINANCIAL_INSTITUTION = "financialInstitution";
const BANKING_INFO_BRANCH_TRANSIT = "branchTransit";
const ANNUAL_INTEREST_RATE = "annualInterestRate";
const DAILY_INTEREST_RATE = "dailyInterestRate";

//Mfa
export const USER_MFA_ENABLED = "isMfaEnabled";
export const ORG_MFA_ENABLED = "isMfaEnabledForOrg";
export const USER_MFA_LAST_ENABLED_DATE = "mfaLastEnabledDateTime";
export const USER_PASSWORD_LAST_MODIFIED_DATE = "passwordLastModifiedDateTime";
export const MFA_PASSWORD_VERIFICATION_STATUS = "isPasswordVerified";
export const IS_RC_LOGIN_FOR_MFA = "isRCLoginForMfa";
export const MFA_CODE_SUBMIT_RESPONSE = "mfaCodeSubmitResponse";
export const MFA_VERIFICATION_CODE_STATUS = "isMfaVerificationCodeSent";
export const MFA_VERIFICATION_CONTACT_TYPE = "mfaContactType";
export const MFA_SMS_SERVICE_DOWN = "mfaSMSServiceDown";
export const MFA_VERIFICATION_MASKED_CONTACT = "mfaMaskedContact";
export const INITIAL_MFA_VERIFICATION_SENT = "initialMfaSent";
export const MFA_VERIFICATION_CODE_ERROR = "mfaVerificationCodeError";
export const MFA_VERIFICATION_STATUS = "isMfaVerified";
export const MFA_SUBMIT_VERIFICATION_CODE_STATUS = "isMfaVerificationCodeSubmitted";
export const SHOW_MFA_NOTIFICATION = "isShowMfaNotification";
export const MFA_PASSWORD_ATTEMPT = "mfaUserPasswordAttemptCount";
export const ERR_PASSWORD_VERIFICATION = "errPasswordVerification";
export const ERR_PARTNER_PARAM_VALIDATION = "errPartnerParameterValidation";
export const MFA_DISABLE_STATUS = "MFA_DISABLE_STATUS";
export const OTP_LOGIN_SEND_COUNT = "otpLoginSendCount";
export const OTP_LOGIN_ATTEMPT_COUNT = "otpLoginAttemptCount";
export const INITIAL_OTP_LOGIN_VERIFICATION_SENT = "initialOtpLoginVerificationSent";
// Standalone ACH
export const ACH_INFO = "achInfo";
export const STANDALONE_ACH_SUPPORTED = "standaloneAchSupported";
export const ACH_ENABLED = "achEnabled";
export const ENABLE_ACH_AUTH = "enableAchAuth";

export const FILLED_DOCUMENT = "filledDocument";
export const DOCUMENT_ID = "documentId";
export const DOCUMENT_VERSION_ID = "documentVersionId";
export const SIGNATURE_REQUIRED = "signatureRequired";
export const EXPECTED_SIGNATURE = "expectedSignature";
export const SUBJECT = "subject";
export const DOC_CONTENT = "content";

export const TODO_ROW = {
    TODO_FIELD_ID,
    TODO_FIELD_COMPLETIONTIME,
    TODO_FIELD_NAME,
    TODO_FIELD_TYPE
};

export const DASHBOARD = {
    PERSONAL_INFO,
    FIRSTNAME,
    LASTNAME,
    EMAIL,
    SSN,
    HOME_PHONE,
    CELL_PHONE,
    ADDRESS,
    ADDRESS2,
    CITY,
    STATE,
    ZIP,
    COMMUNICATION_PREFS,
    COMM_TEXT_MESSAGES,
    COMM_PROMO_MESSAGES,
    COMM_PROMO_CALLS,
    COMM_PROMO_EMAIL,
    VERIFIED,
    CURRENT_LOGIN_TIME: LAST_VISITED,
    ACCOUNT,
    CUSTOMER_ID,
    STATEMENTS,
    HISTORY,
    HISTORY_ITEMS,
    SELECTED_STATEMENT_CONTENT,
    AGREEMENTS,
    SELECTED_AGREEMENT_CONTENT,
    ACTION_ITEMS,
    TODO_BANK_VERIFICATION,
    TODO_ACCOUNT_OWNERSHIP,
    TODO_ACCOUNT_NUMBER,
    TODO_IDENTITY_VERIFICATION,
    TODO_VERIFY_PAIDOFF_PRIOR_LOANS,
    TODO_VERIFY_PREVIOUS_LOAN_PAIDOFF,
    TODO_EMAIL_VERIFICATION,
    TODO_EMPLOYMENT_VERIFICATION,
    TODO_REFERENCES,
    TODO_ESIG,
    TODO_COLLECT_PAYMENT_INFO,
    TODO_KYC,
    TODO_SPOUSAL_INFORMATION,
    SPOUSE_INFO,
    MARITAL_STATUS,
    SPOUSE_FIRST_NAME,
    SPOUSE_LAST_NAME,
    SPOUSE_EMAIL,
    SPOUSE_ADDRESS_1,
    SPOUSE_ADDRESS_2,
    SPOUSE_CITY,
    SPOUSE_STATE,
    SPOUSE_ZIP_CODE,
    BANK_VERIFICATION,
    BANK_VERIFICATION_ITEMS,
    BANK_VERIFICATION_STATUS,
    IS_BANK_VERIFICATION_CONFIRMED,
    SHOW_VERIFY_MY_ACCOUNT_BUTTON,
    DECISION_LOGIC_URL,
    DECISION_LOGIC_STATUS,
    DECISION_LOGIC_DETAILED_STATUS_INFO,
    BV_SYSTEM_ID,
    BV_SYSTEM_NAME,
    BV_SYSTEM_URL,
    MAXIMUM_RETRY,
    BV_REQUEST_ID,
    EMPLOYMENT_VERIFICATION,
    REFERENCES,
    NEXT_EFFECTIVE_DATE,
    IS_TX_EPIC_IMPORT,
    LOC_OFFER_DETAILS,
    OFFERED_INCREASE,
    OFFERED_LIMIT,
    LIMIT_INCREASE_STATUS,
    UNUSED_OFFER_LINK,
    ONLINE_PAYMENT_DETAILS,
    FULL_NAME,
    PAYMENT_CARD_NUMBER,
    CARD_ZIP_CODE,
    CARD_CVV_NUMBER,
    CARD_EXPIRY_DATE,
    ONLINE_PAYMENT_AMOUNT,
    HIDE_MINIMUM_PAYMENT_OPTION,
    DISABLE_ONLINE_PAYMENT_DROPDOWN,
    PAYMENT_ENABLED,
    CARD_PAYMENT_ENABLED,
    IS_LPP_IFRAME_SUPPORTED,
    CARD_PAYMENT_ENABLED_STATUS,
    CURRENT_BALANCE,
    MINIMUM_PAYMENT: MINIMUM_PAYMENT_AMOUNT,
    PREVIOUS_MINIMUM_PAYMENT: PREVIOUS_MINIMUM_PAYMENT_AMOUNT,
    PAST_DUE: PAST_DUE_AMOUNT,
    PREVIOUS_PAST_DUE: PREVIOUS_PAST_DUE_AMOUNT,
    CURRENT_BALANCE_AND_STATEMENT,
    CARD_PAYMENT_DUE_DATE,
    PREVIOUS_CARD_PAYMENT_DUE_DATE,
    NOTIFICATION_MESSAGE,
    CARD_PAYMENT_PREVIEW,
    PAYMENT_REPAY,
    CARD_PAYMENT_FEES,
    CARD_PAYMENT_INTEREST,
    CARD_PAYMENT_OUTSTANDING_BALANCE,
    CARD_PAYMENT_PAST_DUE,
    CARD_PAYMENT_PRINCIPAL,
    CARD_PAYMENT_DETAILS,
    CARD_TRANSACTION_DETAILS,
    CARD_TRANSACTION_DETAILS_INDEX_0,
    CARD_TRANSACTION_DETAILS_INDEX_1,
    CARD_PAYMENT_AUTHORIZATION_CODE,
    CARD_PAYMENT_LAST_FOUR_DIGITS,
    CARD_PAYMENT_AMOUNT,
    CARD_PAYMENT_PROCESSING_DATE,
    CARD_PAYMENT_SUCCESS,
    CARD_PAYMENT_TRANSACTION_ID,
    CARD_PAYMENT_LOAN_DATA,
    CARD_PAYMENT_TRANSACTIONS,
    SHOW_LOADER,
    SHOW_ERROR,
    FLINKS_LOGIN_SUCCESS,
    FLINKS_LOGIN_FAILURE,
    REQUEST_DETAILS,
    REQUEST_DETAILS_STATUS,
    CREDIT_PAYMENT_BATCH_SCHEDULE,
    ACH_EFFECTIVE_DATE,
    IS_SAME_DAY_ACH,
    BATCH_DATE,
    BATCH_TIME,
    LASTEST_SAME_DAY_ACH_TIME_ON_EFFECTIVE_DATE,
    USEROTPINFO,
    ISOTPENABLED,
    ISOTPSENTPOSTLOGIN,
    PHONENUMBER,
    OTPSTATUS,
    SMSSENTDATETIME,
    SENDOTPCODECLICKEDTIME,
    OTP_PROCESSDATA,
    OTP_PROCESSDATA_RESULT,
    OTP_PROCESSDATA_IS_AUTOFUND,
    OTP_PROCESSDATA_IS_NBC,
    PAYROLL_PROCESSDATA,
    PAYROLL_PROCESSDATA_RESULT,
    PAYROLL_PROCESSDATA_IS_AUTOFUND,
    PAYROLL_PROCESSDATA_IS_NBC,
    PAYROLL_PROCESSDATA_PRIMARY_AMOUNT,
    PAYROLL_PROCESSDATA_EMPLOYER_NAME,
    PAYROLL_PROCESSDATA_FULL_NMI,
    PAYROLL_PROCESSDATA_PRIMARY_FREQUENCY,
    PAYROLL_PROCESSDATA_CREDIT_LIMIT,
    PAYROLL_PROCESSDATA_PRIMARY_FREQUENCY_DETAILS,
    PAYROLL_PROCESSDATA_NEXT_PAY_DATE_COMPUTED,
    PAYROLL_PROCESSDATA_BANK_NAME,
    PAYROLL_PROCESSDATA_ACCOUNT_NUMBER,
    PAYROLL_PROCESSDATA_ACCOUNT_TYPE,
    PAYROLL_PROCESSDATA_IS_REJECTED,
    DRAW_ELIGIBILITY_INFO,
    DRAW_ELIGIBILITY_IS_BANK_ACCOUNT_INFO_UPDATED,
    DRAW_ELIGIBILITY_IS_ACTIVE,
    DRAW_ELIGIBILITY_IS_ONLINE_DRAW_ENABLED,
    SHOW_LOC_LOWER_FEE_POPUP,
    DOCUMENT_UPLOAD_ENABLED,
    BV_DATA_READY_RESULT,
    BV_DATA_POLL_PARAMS,
    IS_ACCOUNT_ON_HOLD,
    ONFIDO,
    DASHBOARD_PDF_DOCUMENT,
    CREDIT_EVOLUTION_STATUS,
    CREDIT_EVOLUTION_APPLICABLE,
    CREDIT_EVOLUTION_ENROLLED,
    CREDIT_EVOLUTION_PRE_AUTH_PAYMENT,
    CREDIT_EVOLUTION_REACHED_TOP_CHARGE_TABLE,
    BANKING_INFO_BANK_NAME,
    CURRENT_PRICING_MODEL,
    ESTIMATED_EVOLUTION_DATE,
    CURRENT_SAVING_PERCENTAGE,
    CHARGE_TABLE,
    MODEL_LABEL,
    MODEL_VERSION,
    BASE_RANGE,
    BASE_CHARGE,
    CURRENT_RANGE,
    CURRENT_CHARGE,
    CREDIT_EVOLUTION_DETAILS_RESPONSE,
    PRICING_MODEL_DETAILS,
    CURRENT_ENTITY,
    MONTHLY_ENTITY,
    PRICING_MODEL_CHARGE_TABLE_ITEMS,
    MONTHLY_CHARGE,
    NON_MONTHLY_CHARGE,
    PRICING_MODEL_PRICE_RANGE,
    CEP_DAYS_IN_GOOD_STANDING,
    CEP_EVOLUTION_START_DATE,
    CEP_LAST_ORIGINATED_DATE,
    CEP_LAST_GRADUATED_DATE,
    ACH_INFO,
    STANDALONE_ACH_SUPPORTED,
    ACH_ENABLED,
    ENABLE_ACH_AUTH,
    FILLED_DOCUMENT,
    DOCUMENT_ID,
    DOCUMENT_VERSION_ID,
    SIGNATURE_REQUIRED,
    EXPECTED_SIGNATURE,
    SUBJECT,
    DOC_CONTENT,
    PAYMENT_LPP,
    ORIGINATEDUNIQUEURLVISITED,
    SETTLEMENT_POPUP_CLOSED_FLAG,
    ACH_PAYMENT_INFO,
    ACH_PAYMENT_INFO_PAYMENT_TYPE,
    ACH_PAYMENT_INFO_PAYMENT_METHOD,
    ACH_PAYMENT_INFO_PAYMENT_STATUS,
    ACH_PAYMENT_INFO_OTHER,
    ACH_PAYMENT_INFO_FEES,
    ACH_PAYMENT_INFO_INTEREST,
    ACH_PAYMENT_INFO_PRINCIPAL,
    ACH_PAYMENT_INFO_TOTAL,
    ACH_PAYMENT_INFO_PAYMENT_SCHEDULED_DATE,
    ACH_PAYMENT_INFO_BANK_NAME,
    ACH_PAYMENT_INFO_ROUTING_NUMBER,
    ACH_PAYMENT_INFO_ACCOUNT_TYPE,
    ACH_PAYMENT_INFO_ACCOUNT_NUMBER_LAST_4,
    ACH_PAYMENT_INFO_PAYMENT_PSI_ID
};
// ====== Dashboard Reducer Constants End =====

// ====== Loan Reducer Constants Start =====
export const LOAN_LIMITS = "LOAN_LIMITS";
export const LOAN_LIMITS__MIN_AMOUNT = "LOAN_LIMITS__MIN_AMOUNT";
export const LOAN_LIMITS__MAX_AMOUNT = "LOAN_LIMITS__MAX_AMOUNT";
export const LOAN_LIMITS_BY_ORG_AND_STATE = "LOAN_LIMITS_BY_ORG_AND_STATE";
export const LOAN_LIMITS_BY_ORG_AND_STATE__ID = "LOAN_LIMITS_BY_ORG_AND_STATE__ID";
export const LOAN_LIMITS_BY_ORG_AND_STATE__STATE_NAME = "LOAN_LIMITS_BY_ORG_AND_STATE__STATE_NAME";
export const LOAN_LIMITS_BY_ORG_AND_STATE__STATE_CODE = "LOAN_LIMITS_BY_ORG_AND_STATE__STATE_CODE";
export const LOAN_LIMITS_BY_ORG_AND_STATE__MIN_AMOUNT = "LOAN_LIMITS_BY_ORG_AND_STATE__MIN_AMOUNT";
export const LOAN_LIMITS_BY_ORG_AND_STATE__MAX_AMOUNT = "LOAN_LIMITS_BY_ORG_AND_STATE__MAX_AMOUNT";
export const LOAN_LIMITS_BY_ORG_AND_STATE__STEP_SIZE = "LOAN_LIMITS_BY_ORG_AND_STATE__STEP_SIZE";
export const LOAN_LIMITS_BY_ORG_AND_STATE__IS_SUPPORTED = "LOAN_LIMITS_BY_ORG_AND_STATE__IS_SUPPORTED";
export const LOAN_LIMITS_BY_ORG_AND_STATE__IS_ACTIVE = "LOAN_LIMITS_BY_ORG_AND_STATE__IS_ACTIVE";
export const LOAN_LIMITS_BY_ORG_AND_STATE__IS_PENDING_LICENSE = "LOAN_LIMITS_BY_ORG_AND_STATE__IS_PENDING_LICENSE";
export const LOAN_LIMITS_BY_ORG_AND_STATE__IS_LOAN_DOCS_IN_DASHBOARD =
    "LOAN_LIMITS_BY_ORG_AND_STATE__IS_LOAN_DOCS_IN_DASHBOARD";
export const LOAN_LIMITS_BY_ORG_AND_STATE__IS_ORGANIC_RC_OPTIMIZATION_ENABLED =
    "LOAN_LIMITS_BY_ORG_AND_STATE__IS_ORGANIC_RC_OPTIMIZATION_ENABLED";
export const LOAN_LIMITS_BY_ORG_AND_STATE__IS_NC_AUTO_FUND_ENABLED =
    "LOAN_LIMITS_BY_ORG_AND_STATE__IS_NC_AUTO_FUND_ENABLED";
export const IS_ALL_LOAN_DOC_COMPLETED = "IS_ALL_LOAN_DOC_COMPLETED";
export const LOAN_LIMITS_BY_ORG_AND_STATE__IS_ONE_TIME_PASSWORD_ENABLED =
    "LOAN_LIMITS_BY_ORG_AND_STATE__IS_ONE_TIME_PASSWORD_ENABLED";
export const LOAN_LIMITS_BY_ORG_AND_STATE__IS_ONE_TIME_PASSWORD_RISK_CALL_ENABLED =
    "LOAN_LIMITS_BY_ORG_AND_STATE__IS_ONE_TIME_PASSWORD_RISK_CALL_ENABLED";
export const LOAN_LIMITS_BY_ORG_AND_STATE__IS_PAYROLL_RISK_CALL_ENABLED =
    "LOAN_LIMITS_BY_ORG_AND_STATE__IS_PAYROLL_RISK_CALL_ENABLED";

const BASE_LOAN_ID = "baseLoanId";
const LOAN_HEADER_ID = "loanHeaderId";

// Active Loan Summary Constants Start
const ACTIVE_LOAN_SUMMARY = "activeLoanSummary";
const CREDIT_LIMIT = "creditLimit";
const OUTSTANDING_PRINCIPAL = "outstandingPrincipal";
const AVAILABLE_CREDIT = "availableCredit";

const ACCOUNT_SUMMARY = "accountSummary";
const LAST_DRAW_AMOUNT = "lastDrawAmount";
const LAST_DRAW_DATE = "lastDrawDate";
const LAST_PAYMENT_AMOUNT = "lastPaymentAmount";
const LAST_PAYMENT_DATE = "lastPaymentDate";
const NEXT_PAYMENT_DATE = "nextPaymentDate";

const STATEMENT_SUMMARY = "statementSummary";
const STATEMENT_DATE = "statementDate";
const STATEMENT_BALANCE = "statementBalance";
const MINIMUM_PAYMENT_DUE = "minimumPaymentDue";
const PAYMENT_DUE_DATE = "paymentDueDate";
const PAYMENT_DUE = "paymentAmount";
const NOTIFICATION_CODE = "notificationCode";
const LAST_PAYMENT = "lastPayment";
const HAS_FUTURE_COLLECTION = "hasFutureScheduledCollectionPayments";
const HAS_GAP_TODO_AMOUNT = "hasCollectionGapTodoAmount";
const IS_PSI_RETURNED = "isPSIReturned";
const PREVIOUS_PAYMENT_DUE = "previousPaymentAmount";
const PREVIOUS_PAYMENT_DUE_DATE = "previousPaymentDueDate";
const PAYMENT_INFO_NOT_AVAILABLE = "paymentInfoNotAvailable";
const THEORETICAL_SEP_AMOUNT = "theoreticalSepAmount";
const AMOUNT_PAST_DUE = "pastDueAmount";
const DRAW_ATTEMPT_DETAILS = "scheduledDraw";
const DRAW_SCHEDULED = "drawScheduled";
const DRAW_AMOUNT = "drawAmount";
const SCHEDULED_DATE = "scheduledDate";
const IS_DISABLE_DRAW_DUE_TO_INACTIVITY = "isDisableDrawDueToInactivity";
const PENDING_DRAW = "pendingDraw";
const IS_ELIGIBLE_FOR_CONCESSION = "isEligibleForConcession";
const IS_ELIGIBLE_FOR_ACH_SCHEDULING = "eligibleForAchScheduling";
const ONLINE_COLLECTION_SCHEDULED_ACH_DATE = "onlineCollectionScheduledAchDate";
const REDUCED_PAST_DUE_AMOUNT = "reducedPastDueAmount";
const REDUCED_CURRENT_BALANCE = "reducedCurrentBalance";
const SHOULD_SHOW_PAYMENT_DUE_TODAY = "shouldShowPaymentDueToday";

// Active Loan Summary Constants Ends

// Active Loan Constant Starts
const ACTIVE_LOAN = "activeLoan";
const LOAN_CUSTOMERID = "customerId";
const LOAN_TYPE = "loanType";
const ACTIVE_LOAN_AMOUNT = "loanAmount";
const LOAN_CREATED_DATE = "createdDate";
const LOAN_ORIGINATION_DATE = "originationDate";
const LOAN_EFFECTIVE_DATE = "effectiveDate";
const LOAN_DUE_DATE = "paymentDueDate";
const LOAN_PAID_OFF_DATE = "paidOffDate";
const LOAN_WITHDRAWN_DATE = "withdrawnDate";
const LOAN_REAPPLY_DATE = "reapplyDate";
const LOAN_STATUS = "loanStatus";
const NON_WITHDRAWN_LOAN_STATUS = "nonWithdrawnLoanStatus";
const LOAN_EXTENDED_STATUS = "loanExtendedStatus";
const CANCELLATION_TYPE = "cancellationType";
const EXTENDED_STATUS = "extendedStatus";
const LOAN_LEAD_SEQUENCE_ID = "leadSequenceId";
const LOAN_LMS_ENTITY_ID = "lmsEntityId";
const LOAN_ENTITY_NAME = "entityName";
const LOAN_LOAN_HEADER_ID = "loanHeaderId";
const LOAN_CAMPAIGN_NAME = "campaignName";
const LOAN_USER_ID = "userId";
const LOAN_IS_ONLINE_DRAW_ENABLED = "isOnlineDrawEnabled";
const LOAN_IS_ONLINE_PAYMENT_ENABLED = "isOnlinePaymentEnabled";
const LOAN_IS_COMPLIANCE_ALERT_ACTIVE = "isComplianceAlertActive";
const LOAN_IS_NC_AUTO_FUND_FLOW = "isNCAutoFundFlow";
const LOAN_IS_AUTO_FUND = "isAutoFund";
const LOAN_IS_NOBANKCALL = "isNoBankCall";
// Active Loan Constant Ends

// Loan Docs Constants Start
const LOAN_DOCS = "loanDocs";
const LOAN_DOCS_DOCUMENT_VERSION_ID = "documentVersionId";
const LOAN_DOCS_DOCUMENT_SUBJECT = "docSubject";
const LOAN_DOCS_DOC_ORDER = "docOrder";
const LOAN_DOCS_DOCUMENT_NAME = "docName";
const LOAN_DOCS_COMPLETED = "completed";
const LOAN_DOCS_CONTENT = "loanDocContent";
const LOAN_DOCS_SIGNATURE_REQUIRED = "signatureRequired";
const LOAN_DOCS_SSN_LAST_FOUR = "ssnLastFour";
// Loan Docs Constants Ends

// PAYMENT SCHEDULE CONSTANTS START

const PAYMENT_SCHEDULE_ITEMS = "paymentScheduleItems";
const PAYMENT_INFO_DETAILS = "paymentInfoDetails";
const PSI_ID = "psiId";
const PAYMENT_DATE = "paymentDate";
const PAYMENT_AMOUNT = "paymentAmount";
const IS_COLLECTION = "isCollection";

// Repay Payment Transaction Info
const PAYMENT_TRANSACTION_INFO = "paymentTransactionInfo";
const TOTAL_AMOUNT = "totalAmount";
const TRANSACTION_REF_NUMBER = "transactionRefNumber";
const AUTH_CODE = "authCode";
const CARD_LAST_FOUR_DIGIT = "cardLastFourDigit";
const TRANSACTION_DATE_TIME = "transactionDateTime";
const MESSAGE = "msg";
const MESSAGE_DETAILS = "msgDetails";

// Payment Schedule Constants End

//CREDIT LIMIT INCREASE CONSTATNS START

const NEW_CREDIT_LIMIT = "newCreditLimit";
const OLD_CREDIT_LIMIT = "oldCreditLimit";

// TDC Constants
const TDC_LOAN = "tdcLoan";
const TDC_LOAN_REDIRECT_PAGE = "redirectPage";
const TDC_APPL_NO = "applNo";
const TDC_APPL_STATUS = "applStatus";
const TDC_LOAN_STATUS = "loanStatus";
const TDC_MERCHSTORE_ID = "merchStoreId";
const TDC_USER_ID = "userId";
export const LOAN = {
    LOAN_LIMITS,
    LOAN_LIMITS__MIN_AMOUNT,
    LOAN_LIMITS__MAX_AMOUNT,
    LOAN_LIMITS_BY_ORG_AND_STATE,
    LOAN_LIMITS_BY_ORG_AND_STATE__ID,
    LOAN_LIMITS_BY_ORG_AND_STATE__STATE_NAME,
    LOAN_LIMITS_BY_ORG_AND_STATE__STATE_CODE,
    LOAN_LIMITS_BY_ORG_AND_STATE__MIN_AMOUNT,
    LOAN_LIMITS_BY_ORG_AND_STATE__MAX_AMOUNT,
    LOAN_LIMITS_BY_ORG_AND_STATE__STEP_SIZE,
    LOAN_LIMITS_BY_ORG_AND_STATE__IS_SUPPORTED,
    LOAN_LIMITS_BY_ORG_AND_STATE__IS_ACTIVE,
    LOAN_LIMITS_BY_ORG_AND_STATE__IS_PENDING_LICENSE,
    LOAN_LIMITS_BY_ORG_AND_STATE__IS_LOAN_DOCS_IN_DASHBOARD,
    LOAN_LIMITS_BY_ORG_AND_STATE__IS_ORGANIC_RC_OPTIMIZATION_ENABLED,
    LOAN_LIMITS_BY_ORG_AND_STATE__IS_NC_AUTO_FUND_ENABLED,
    LOAN_LIMITS_BY_ORG_AND_STATE__IS_ONE_TIME_PASSWORD_ENABLED,
    LOAN_LIMITS_BY_ORG_AND_STATE__IS_ONE_TIME_PASSWORD_RISK_CALL_ENABLED,
    LOAN_LIMITS_BY_ORG_AND_STATE__IS_PAYROLL_RISK_CALL_ENABLED,

    ACTIVE_LOAN_SUMMARY,
    BASE_LOAN_ID,
    LOAN_HEADER_ID,
    CREDIT_LIMIT,
    OUTSTANDING_PRINCIPAL,
    AVAILABLE_CREDIT,
    ACCOUNT_SUMMARY,
    LAST_DRAW_AMOUNT,
    LAST_DRAW_DATE,
    LAST_PAYMENT_AMOUNT,
    LAST_PAYMENT_DATE,
    NEXT_PAYMENT_DATE,
    STATEMENT_SUMMARY,
    STATEMENT_DATE,
    STATEMENT_BALANCE,
    MINIMUM_PAYMENT_DUE,
    PAYMENT_DUE_DATE,
    PAYMENT_DUE,
    AMOUNT_PAST_DUE,
    DRAW_ATTEMPT_DETAILS,
    DRAW_SCHEDULED,
    DRAW_AMOUNT,
    SCHEDULED_DATE,
    IS_DISABLE_DRAW_DUE_TO_INACTIVITY,
    PENDING_DRAW,
    IS_ELIGIBLE_FOR_CONCESSION,
    IS_ELIGIBLE_FOR_ACH_SCHEDULING,
    ONLINE_COLLECTION_SCHEDULED_ACH_DATE,
    REDUCED_PAST_DUE_AMOUNT,
    REDUCED_CURRENT_BALANCE,
    SHOULD_SHOW_PAYMENT_DUE_TODAY,

    ACTIVE_LOAN,
    LOAN_CUSTOMERID,
    LOAN_TYPE,
    ACTIVE_LOAN_AMOUNT,
    LOAN_CREATED_DATE,
    LOAN_ORIGINATION_DATE,
    LOAN_EFFECTIVE_DATE,
    LOAN_DUE_DATE,
    LOAN_PAID_OFF_DATE,
    LOAN_WITHDRAWN_DATE,
    LOAN_REAPPLY_DATE,
    LOAN_STATUS,
    NON_WITHDRAWN_LOAN_STATUS,
    LOAN_EXTENDED_STATUS,
    EXTENDED_STATUS,
    CANCELLATION_TYPE,
    LOAN_LEAD_SEQUENCE_ID,
    LOAN_LMS_ENTITY_ID,
    LOAN_ENTITY_NAME,
    LOAN_LOAN_HEADER_ID,
    LOAN_CAMPAIGN_NAME,
    LOAN_USER_ID,
    LOAN_IS_ONLINE_DRAW_ENABLED,
    LOAN_IS_ONLINE_PAYMENT_ENABLED,
    LOAN_IS_COMPLIANCE_ALERT_ACTIVE,
    LOAN_IS_NC_AUTO_FUND_FLOW,
    LOAN_IS_AUTO_FUND,
    LOAN_IS_NOBANKCALL,

    LOAN_DOCS,
    LOAN_DOCS_DOCUMENT_VERSION_ID,
    LOAN_DOCS_DOCUMENT_SUBJECT,
    LOAN_DOCS_DOC_ORDER,
    LOAN_DOCS_DOCUMENT_NAME,
    LOAN_DOCS_COMPLETED,
    LOAN_DOCS_CONTENT,
    LOAN_DOCS_SIGNATURE_REQUIRED,
    LOAN_DOCS_SSN_LAST_FOUR,

    PAYMENT_SCHEDULE_ITEMS,
    PAYMENT_INFO_DETAILS,
    PAYMENT_TRANSACTION_INFO,
    TRANSACTION_REF_NUMBER,
    AUTH_CODE,
    CARD_LAST_FOUR_DIGIT,
    MESSAGE,
    MESSAGE_DETAILS,
    TOTAL_AMOUNT,
    TRANSACTION_DATE_TIME,
    NOTIFICATION_CODE,
    LAST_PAYMENT,
    HAS_GAP_TODO_AMOUNT,
    IS_PSI_RETURNED,
    HAS_FUTURE_COLLECTION,
    PREVIOUS_PAYMENT_DUE,
    NOTIFICATION_MESSAGE,
    PREVIOUS_PAYMENT_DUE_DATE,
    PAYMENT_INFO_NOT_AVAILABLE,
    THEORETICAL_SEP_AMOUNT,
    CURRENT_BALANCE,
    PSI_ID,
    PAYMENT_DATE,
    PAYMENT_AMOUNT,
    IS_COLLECTION,

    NEW_CREDIT_LIMIT,
    OLD_CREDIT_LIMIT,

    TDC_LOAN,
    TDC_LOAN_REDIRECT_PAGE,
    TDC_APPL_NO,
    TDC_APPL_STATUS,
    TDC_LOAN_STATUS,
    TDC_MERCHSTORE_ID,
    TDC_USER_ID,

    FEE_LEVEL,

    BANKING_INFO,
    BANKING_INFO_BANK_NAME,
    BANKING_INFO_ACCOUNT_NUMBER,
    BANKING_INFO_ACCOUNT_TYPE,
    DRAW_DISABLE_DUE_TO_OVER_PAYMENT,
    BANKING_INFO_FINANCIAL_INSTITUTION,
    BANKING_INFO_BRANCH_TRANSIT,
    ANNUAL_INTEREST_RATE,
    DAILY_INTEREST_RATE,
};
// ====== Loan Reducer Constants End =====

// ====== Progress Bar Reducer Constants Start =====
const LATEST_APPLICATION_STEP = "latest_application_step";
const LAST_PATHNAME = "last_pathname";

export const PROGRESSBAR = {
    LATEST_APPLICATION_STEP,
    LAST_PATHNAME
};
// ====== Progress Bar Reducer Constants End =====

// ====== Content Reducer Constants Start =====

const CONSENT = "consent";
const PRIVACY_POLICY = "Privacy Policy";
const TERMS_OF_USE = "Terms of Use";
const ECONSENT = "EConsent";
const JURY_TRIAL = "Jury Trial";
const TERMS_SMS = "SMS Terms and Conditions";
const BANK_PRIVACY_POLICY = "Bank Privacy Policy";
const AUTO_DIALER_TERMS = "Auto Dialers";
const TERMS_AND_CONDITIONS = "Terms and Conditions";
const SDR_CONSENT = "SDR Consent";
const SDR_CONSENT_POPUP = "SDR Consent Popup";
export const CONTENT = {
    CONSENT,
    PRIVACY_POLICY,
    TERMS_OF_USE,
    ECONSENT,
    JURY_TRIAL,
    TERMS_SMS,
    BANK_PRIVACY_POLICY,
    AUTO_DIALER_TERMS,
    TERMS_AND_CONDITIONS,
    SDR_CONSENT,
    SDR_CONSENT_POPUP
};
// ====== Content Reducer Constants End ====

// ====== Organization Reducer Constants Start =====
const CONFIG = "config";
const ORGANIZATION_ID = "organizationId";
const ORGANIZATION_NAME = "organizationName";
const RESOURCE_FOLDERNAME = "resourceFolderName";
const API_ENDPOINT = "apiEndpoint";
const ORGANIZATION_PROD_TIERKEY = "organizationProdTierKey";
const ORGANIZATION_TEST_TIERKEY = "organizationTestTierKey";
const ORGANIZATION_RAL_PROD_TIERKEY = "organizationRALProdTierKey";
const ORGANIZATION_RAL_TEST_TIERKEY = "organizationRALTestTierKey";
const ORGANIZTION_RAL_PROD_DM_TIERKEY = "organizationRALProdDMTierKey";
const ORGANIZATION_RAL_TEST_DM_TIERKEY = "organizationRALTestDMTierKey";
const ORGANIZATION_LOC_RC_PROD_TIERKEY = "organizationLOCRCProdTierKey";
const ORGANIZATION_LOC_RC_TEST_TIERKEY = "organizationLOCRCTestTierKey";
const ORGANIZATION_LOC_RC_PROD_DM_TIERKEY = "organizationLOCRCProdDMTierKey";
const ORGANIZATION_LOC_RC_TEST_DM_TIERKEY = "organizationLOCRCTestDMTierKey";
const ORGANIZATION_SEP_RC_PROD_TIERKEY = "organizationSEPRCProdTierKey";
const ORGANIZATION_SEP_RC_TEST_TIERKEY = "organizationSEPRCTestTierKey";
const ORGANIZATION_SEP_RC_PROD_DM_TIERKEY = "organizationSEPRCProdDMTierKey";
const ORGANIZATION_SEP_RC_TEST_DM_TIERKEY = "organizationSEPRCTestDMTierKey";
const RESOURCE_CDN = "resourceCDN";
const GTMKEY = "gtmKey";
const BUILD_PROFILE = "buildProfile";
const SUPPORT_NUMBER = "supportNumber";
const SKIP_PAYMENTCARD_VALIDATION = "skipPaymentCardValidation";
const BANK_VERIFICATION_CONFIRM_ENABLED = "bankVerificationConfirmEnabled";
const ORG_CONTENT = "orgContent";
const ORG_CONTENT_KEYS = "orgContentKeys";

const ORG_CONTENT_KEY_STATE = "state";
const ORG_CONTENT_KEY_PRODUCT = "product";

const IS_CHAT_ACTIVE = "isChatActive";
const CHAT_HOURS = "chatHours";
const SERVER_TIME = "serverTime";
const SUB_ID_TRACKING_THRESHOLD = "subIdTrackingThreshold";
const CHARGE_TABLE_SWITCH_DATE = "chargeTableSwitchDate";
const IS_PHONE_VERIFICATION_SMS_ENABLED = "isPhoneVerificationSMSEnabled";
const IS_PHONE_VERIFICATION_LANDLINE_ENABLED = "isPhoneVerificationLandlineEnabled";
const IS_PHONE_VERIFICATION_SKIP_ENABLED = "isPhoneVerificationSkipEnabled";
const IS_EMAIL_VERIFICATION_VIA_OTP_ENABLED = "isEmailVerificationViaOTPEnabled";
const IS_CLOUDFLARE_TURNSTILE_VALIDATION_ENABLED = "isCloudflareTurnstileValidationEnabled";
const IS_CANCEL_DRAW_ENABLED = "isCancelDrawEnabled";
const IS_LOAN_AGREEMENT_REDESIGN_ENABLED = "isLoanAgreementRedesignEnabled";
const IS_SDR_CONSENT_ENABLED = "isSDRConsentEnabled";
const IS_SKIP_EMAIL_VALIDATION = "skipEmailValidation"


const PRODUCT_TYPE = "productType";
export const ORGANIZATION = {
    CONFIG,
    UI,
    ORG_CONTENT,
    ORG_CONTENT_KEYS,
    ORG_CONTENT_KEY_STATE,
    ORG_CONTENT_KEY_PRODUCT,
    ORGANIZATION_ID,
    ORGANIZATION_NAME,
    API_ENDPOINT,
    ORGANIZATION_PROD_TIERKEY,
    ORGANIZATION_TEST_TIERKEY,
    ORGANIZATION_RAL_PROD_TIERKEY,
    ORGANIZATION_RAL_TEST_TIERKEY,
    ORGANIZTION_RAL_PROD_DM_TIERKEY,
    ORGANIZATION_RAL_TEST_DM_TIERKEY,
    ORGANIZATION_LOC_RC_PROD_TIERKEY,
    ORGANIZATION_LOC_RC_TEST_TIERKEY,
    ORGANIZATION_LOC_RC_PROD_DM_TIERKEY,
    ORGANIZATION_LOC_RC_TEST_DM_TIERKEY,
    ORGANIZATION_SEP_RC_PROD_TIERKEY,
    ORGANIZATION_SEP_RC_TEST_TIERKEY,
    ORGANIZATION_SEP_RC_PROD_DM_TIERKEY,
    ORGANIZATION_SEP_RC_TEST_DM_TIERKEY,
    RESOURCE_FOLDERNAME,
    RESOURCE_CDN,
    GTMKEY,
    BUILD_PROFILE,
    SUPPORT_NUMBER,
    IS_CHAT_ACTIVE,
    SKIP_PAYMENTCARD_VALIDATION,
    IS_SKIP_EMAIL_VALIDATION,
    BANK_VERIFICATION_CONFIRM_ENABLED,
    CHAT_HOURS,
    SERVER_TIME,
    SUB_ID_TRACKING_THRESHOLD,
    CHARGE_TABLE_SWITCH_DATE,
    IS_PHONE_VERIFICATION_SMS_ENABLED,
    IS_PHONE_VERIFICATION_LANDLINE_ENABLED,
    IS_PHONE_VERIFICATION_SKIP_ENABLED,
    IS_EMAIL_VERIFICATION_VIA_OTP_ENABLED,
    IS_CLOUDFLARE_TURNSTILE_VALIDATION_ENABLED,
    IS_CANCEL_DRAW_ENABLED,
    IS_LOAN_AGREEMENT_REDESIGN_ENABLED,
    PRODUCT_TYPE,
    IS_SDR_CONSENT_ENABLED
};
// ====== Organization Reducer Constants End ====

export const PAYMENT_PROTECTION_PLAN_DETAILS = "paymentProtectionPlanDetails";
export const IS_PRE_FILL_BANKING_INFORMATION = "isPrefilledBankInformation";
export const INSURANCE_USER_FIRST_NAME = "firstName";
export const INSURANCE_USER_LAST_NAME = "lastName";
export const INSURANCE_USER_EMAIL = "emailAddress";
export const INSURANCE_BANK_INSTITUTION_NUMBER = "institutionNumber";
export const INSURANCE_BANK_TRANSIT_NUMBER = "transitNumber";
export const IS_INSURANCE_SUPPORTED = "insuranceSupported";
export const INSURANCE_STATUS = "status";
export const PAYMENT_PROTECTION_PLAN_ACKNOWLEDGEMENT = "paymentProtectionPlanAcknowledgement";
export const INSURANCE_DISCLOSURE_AND_ACKNOWLEDGEMENT = "insuranceDisclosureAndAcknowledgement";
export const DATE_OF_AUTH_AGREEMENT = "dateOfAuthAgreement";
export const INSURANCE_POLICY_NUMBER = "policyNumber";
export const INSURANCE_ENQUIRY_EMAIL = "enquiryEmail";
export const INSURANCE_ENQUIRY_PHONE = "enquiryPhone";
export const CUSTOMER_DASHBOARD_URL = "customerDashboardUrl";
export const INSURANCE_POLICY_DOCUMENT_URL = "policyDocumentUrl";
export const IS_ALL_ACTION_ITEM_COMPLETED_FOR_INSURANCE = "isAllActionItemCompleted";
export const SHOW_INSURANCE_POPUP = "showInsurancePopup";

const LOGIN_PAGE = "loginBehavior";
const BOT_DETECTION = "botDetection";
const IS_MC_IDENTITY_PACKAGE_LOADING = 'isMcIdentityPackageLoading';
const IS_MC_IDENTITY_LOGIN_PACKAGE_LOADED = "isMcIdentityLoginPackageLoaded";
const IS_MC_IDENTITY_BOT_DETECTION_PACKAGE_LOADED = "isMcIdentityBotDetectionPackageLoaded";
const DETECTION_STARTED = "didBotDetectionStarted";
const DATA_SUBMITTED = "isDataSubmitted";
const MC_IDENTITY_SESSION_ID = "mcIdentitySessionId";
const MC_IDENTITY_TRACKING_PAGE = "mcIdentityTrackingPage";
const MC_IDENTITY_LOGIN_BEHAVIOUR_ENABLED = "mcIdentityLoginBehaviourEnabled";
const MC_IDENTITY_BOT_DETECTION_ENABLED = "mcIdentityBotDetectionEnabled";
const MC_IDENTITY_LOGIN_BEHAVIOUR_CLIENT_ID = "mcIdentityLoginBehaviourClientId";
const MC_IDENTITY_BOT_DETECTION_CLIENT_ID = "mcIdentityBotDetectionClientId";

export const MC_IDENTITY = {
    MC_IDENTITY_SESSION_ID,
    IS_MC_IDENTITY_LOGIN_PACKAGE_LOADED,
    IS_MC_IDENTITY_BOT_DETECTION_PACKAGE_LOADED,
    LOGIN_PAGE,
    BOT_DETECTION,
    DETECTION_STARTED,
    DATA_SUBMITTED,
    MC_IDENTITY_LOGIN_BEHAVIOUR_ENABLED,
    MC_IDENTITY_BOT_DETECTION_ENABLED,
    MC_IDENTITY_LOGIN_BEHAVIOUR_CLIENT_ID,
    MC_IDENTITY_BOT_DETECTION_CLIENT_ID,
    MC_IDENTITY_TRACKING_PAGE,
    IS_MC_IDENTITY_PACKAGE_LOADING
};

// ====== Ach Reducer Constants Start ====

//Ach Scheduling Info
const ACH_SCHEDULING_INFO = "achSchedulingInfo";
const ACH_SCHEDULING_INFO_BANK_NAME = "bankName";
const ACH_SCHEDULING_INFO_ROUTING_NUMBER = "routingNumber";
const ACH_SCHEDULING_INFO_ACCOUNT_TYPE = "accountType";
const ACH_SCHEDULING_INFO_ACCOUNT_NUMBER_LAST_4 = "accountNumberLast4";
const ACH_SCHEDULING_INFO_ELIGIBLE = "isEligibleForAchScheduling";
const ACH_SCHEDULING_INFO_PAYMENT_DATES = "paymentDates";
const ACH_SCHEDULING_INFO_CUT_OFF_TIME = "cutOffTime";

export const ACH = {
    ACH_SCHEDULING_INFO,
    ACH_SCHEDULING_INFO_BANK_NAME,
    ACH_SCHEDULING_INFO_ROUTING_NUMBER,
    ACH_SCHEDULING_INFO_ACCOUNT_TYPE,
    ACH_SCHEDULING_INFO_ACCOUNT_NUMBER_LAST_4,
    ACH_SCHEDULING_INFO_ELIGIBLE,
    ACH_SCHEDULING_INFO_PAYMENT_DATES,
    ACH_SCHEDULING_INFO_CUT_OFF_TIME
};
// ====== Ach Reducer Constants End ====
