import _ from "lodash";
import {put, select} from "redux-saga/effects";

import {history} from "../../_history";
import * as trackingActions from "../action/trackingActions";
import * as field from "../../shared/constant/field";
import * as routes from "../../shared/constant/route";
import {setBrowserTitle} from "../../shared/utility/contentutility";
import * as utility from "../../shared/utility/utility";

// -------------------------- Auth --------------------------

export function* miscAuthActions() {
    yield changeTitleForAuth();
    yield changeMetaForAuth();
}

function* changeTitleForAuth() {
    let {location, content} = yield select(state => ({
        location: state[field.REDUCER_NODE_ROUTER].location,
        content: state[field.REDUCER_NODE_ORGANIZATION][field.ORGANIZATION.ORG_CONTENT]
    }));

    if (location.pathname === routes.APPLICATION_HOME_PAGE || location.pathname === routes.LOGIN) {
        setBrowserTitle(`Log into your account | ${content.common.orgNameForBrowserTitle}`);
    } else if (location.pathname.indexOf(routes.APPLICATION_STATE_LOGIN) > -1) {
        setBrowserTitle(`State Login | ${content.common.orgNameForBrowserTitle}`);
    } else if (location.pathname.indexOf(routes.RESET_PASSWORD) > -1) {
        setBrowserTitle(`Reset Password | ${content.common.orgNameForBrowserTitle}`);
    } else if (location.pathname.indexOf(routes.UPDATE_PASSWORD) > -1) {
        setBrowserTitle(`Update Password | ${content.common.orgNameForBrowserTitle}`);
    } else if (location.pathname.indexOf(routes.VERIFY_ACCOUNT_ROUTE_PARAMETER) > -1) {
        setBrowserTitle(`Verify Account | ${content.common.orgNameForBrowserTitle}`);
    } else if (location.pathname.indexOf(routes.CHAT_PAGE) > -1) {
        setBrowserTitle(`Chat | ${content.common.orgNameForBrowserTitle}`);
    } else if (location.pathname.indexOf(routes.FEEDBACK_FORM_PAGE_SUBMITTED) > -1) {
        setBrowserTitle(`Feedback Submitted | ${content.common.orgNameForBrowserTitle}`);
    } else if (location.pathname.indexOf(routes.FEEDBACK_FORM_PAGE) > -1) {
        setBrowserTitle(`Feedback | ${content.common.orgNameForBrowserTitle}`);
    } else if (location.pathname === routes.PROFILE_MFA_SET_UP_PAGE ||
        location.pathname === routes.LOGIN_MFA_SET_UP_PAGE ||
        location.pathname === routes.PROFILE_MFA_VERIFICATION_PAGE ||
        location.pathname === routes.LOGIN_MFA_SET_UP_VERIFICATION_PAGE) {
        setBrowserTitle(`Set Up 2FA | ${content.common.orgNameForBrowserTitle}`);
    } else if (location.pathname === routes.LOGIN_MFA_VERIFICATION_SMS_PAGE ||
        location.pathname === routes.LOGIN_MFA_VERIFICATION_EMAIL_PAGE) {
        setBrowserTitle(`Verify Login | ${content.common.orgNameForBrowserTitle}`);
    } else if (location.pathname === routes.OTP_EMAIL_LOGIN_PAGE) {
        setBrowserTitle(`Verify Log In | ${content.common.orgNameForBrowserTitle}`);
    } else if (location.pathname === routes.EMAIL_LOGIN_PAGE) {
        setBrowserTitle(`Email Login | ${content.common.orgNameForBrowserTitle}`);
    } else if(location.pathname.indexOf(routes.UPDATE_PASSWORD_WITH_TOKEN) > -1){
        setBrowserTitle(`Update Password | ${content.common.orgNameForBrowserTitle}`)
    } else if(location.pathname === routes.OPTOUT){
        setBrowserTitle(`Opt-Out | ${content.common.orgNameForBrowserTitle}`)
    } else {
        setBrowserTitle(content.common.orgNameForBrowserTitle);
    }
}

function* changeMetaForAuth() {
    let {location, content} = yield select(state => ({
        location: state[field.REDUCER_NODE_ROUTER].location,
        content: state[field.REDUCER_NODE_ORGANIZATION][field.ORGANIZATION.ORG_CONTENT]
    }));

    if (location.pathname === routes.APPLICATION_HOME_PAGE || location.pathname === routes.LOGIN) {
        document.getElementsByTagName("meta").description.content =
            content.common.orgNameForBrowserTitle +
            " customers can use their email & password to login to their account and view their balance.";
    } else {
        document.getElementsByTagName("meta").description.content = content.common.orgNameForBrowserTitle + " sign up.";
    }

    if (location.pathname === routes.PROFILE_MFA_SET_UP_PAGE) {
        yield put(trackingActions.viewMfaSetUpPage());
    } else if (location.pathname === routes.PROFILE_MFA_VERIFICATION_PAGE ||
        location.pathname === routes.LOGIN_MFA_SET_UP_VERIFICATION_PAGE) {
        yield put(trackingActions.viewMfaSetUpVerifyPage());
    }
}

// -------------------------- Dashboard --------------------------

export function* miscDashboardActions() {
    yield changeTitleForDashboard();
    yield trackDashboard();
}

function* changeTitleForDashboard() {
    let content = yield select(state => state[field.REDUCER_NODE_ORGANIZATION][field.ORGANIZATION.ORG_CONTENT]);
    let location = yield select(state => state[field.REDUCER_NODE_ROUTER].location);

    if (location.pathname.indexOf(routes.DASHBOARD_OTP_EMAIL_VERIFICATION) > -1) {
        setBrowserTitle(`${content.application.otpEmailVerification.pageTitle} | ${content.common.orgNameForBrowserTitle}`);
    } else if(location.pathname.indexOf(routes.DASHBOARD_UPDATE_PASSWORD) > -1) {
        setBrowserTitle(`Update Password | ${content.common.orgNameForBrowserTitle}`);
    } else if (location.pathname === routes.PROFILE_MFA_DISABLE_PAGE) {
        setBrowserTitle(`Disable 2FA | ${content.common.orgNameForBrowserTitle}`);
    }
    else {
        setBrowserTitle(`My Account | ${content.common.orgNameForBrowserTitle}`);
    }
}

function* trackDashboard() {
    let location = yield select(state => state[field.REDUCER_NODE_ROUTER].location);

    if (location.pathname.indexOf(routes.DASHBOARD_VIEW_PROFILE) > -1) {
        yield put(trackingActions.viewProfilePage());
    } else if (location.pathname.indexOf(routes.DASHBOARD_AGREEMENTS) > -1) {
        yield put(trackingActions.viewAgreementsPage());
    } else if (location.pathname.indexOf(routes.DASHBOARD_STATEMENTS) > -1) {
        if (!_.get(location, `params.id`)) {
            yield put(trackingActions.viewStatementsPage());
        }
    } else if (location.pathname.indexOf(routes.DASHBOARD_DRAW_REQUEST) > -1) {
        yield put(trackingActions.viewDrawRequestPage());
    } else if (location.pathname.indexOf(routes.DASHBOARD_DRAW_RESULT) > -1) {
        yield put(trackingActions.viewDrawResultPage());
    } else if (location.pathname.indexOf(routes.DASHBOARD_CANCEL_ADVANCE_REQUEST || location.pathname.indexOf(routes.DASHBOARD_CANCEL_DRAW_REQUEST))> -1){
        yield put(trackingActions.viewCancelDrawPage());
    }else if (location.pathname.indexOf(routes.DASHBOARD_BANK_VERIFICATION) > -1) {
        yield put(trackingActions.viewBankVerificationPage());
    } else if (location.pathname.indexOf(routes.DASHBOARD_BANK_VERIFICATION_REDIRECT) > -1) {
        yield put(trackingActions.viewBankVerificationRedirectPage());
    } else if (location.pathname.indexOf(routes.DASHBOARD_CONFIRM_BANK_VERIFICATION) > -1) {
        yield put(trackingActions.viewConfirmBankVerificationPage());
    } else if (location.pathname.indexOf(routes.DASHBOARD_VIEW_MYACCOUNT) > -1) {
        yield put(trackingActions.viewAccountPage());
    } else if (location.pathname.indexOf(routes.DASHBOARD_RELOAN_PAGE) > -1) {
        yield put(trackingActions.viewDashboardReloanPage());
    } else if (location.pathname.indexOf(routes.DASHBOARD_HISTORY_PAGE) > -1) {
        let baseLoanId = location.pathname.match(/\d+/g);
        if (utility.isEmptyOrNullString(baseLoanId)) {
            yield put(trackingActions.viewHistoryPage());
        } else {
            yield put(trackingActions.viewLoanDetailsPage(baseLoanId));
        }
    } else if (location.pathname.indexOf(routes.DASHBOARD_CREDIT_INCREASE_PAGE) > -1) {
        yield put(trackingActions.viewCreditLimitIncreasePage());
    } else if (location.pathname.indexOf(routes.DASHBOARD_CREDIT_INCREASE_CONFIRMATION_PAGE) > -1) {
        yield put(trackingActions.viewCreditLimitIncreaseConfirmationPage());
    } else if (location.pathname.indexOf(routes.ONLINE_PAYMENT_PAGE) > -1) {
        yield put(trackingActions.viewAddPaymentPage());
    } else if (location.pathname.indexOf(routes.CONFIRM_PAYMENT_PAGE) > -1) {
        yield put(trackingActions.viewConfirmPaymentPage());
    } else if (location.pathname.indexOf(routes.ONLINE_PAYMENT_CONFIRMATION_PAGE) > -1) {
        yield put(trackingActions.viewPaymentSuccessPage());
    } else if (location.pathname.indexOf(routes.DASHBOARD_INVALID_CL_OFFER_PAGE) > -1) {
        yield put(trackingActions.viewInvalidIncreaseOfferPage());
    } else if (location.pathname.indexOf(routes.DASHBOARD_VIEW_DOCUMENT_UPLOAD_PAGE) > -1) {
        yield put(trackingActions.viewDocumentUploadPage());
    } else if (location.pathname.indexOf(routes.DASHBOARD_VIEW_DOCUMENT_UPLOAD_CONFIRMATION) > -1) {
        yield put(trackingActions.viewDocumentUploadConfirmationPage());
    } else if (location.pathname.indexOf(routes.DASHBOARD_IDENTITY_VERIFICATION_PAGE) > -1) {
        yield put(trackingActions.viewIdentityVerificationPage());
    } else if (location.pathname.indexOf(routes.PROFILE_VIEW_MFA_VERIFY_PASSWORD) > -1) {
        yield put(trackingActions.viewEnableMfaPage());
    } else if (location.pathname.indexOf(routes.PROFILE_MFA_SET_UP_PAGE) > -1) {
        yield put(trackingActions.viewMfaSetUpPage());
    } else if (location.pathname.indexOf(routes.PROFILE_MFA_DISABLE_PAGE) > -1) {
        yield put(trackingActions.viewDisableMfaPage());
    } else if (location.pathname.indexOf(routes.DASHBOARD_OTP_EMAIL_VERIFICATION) > -1){
        yield put(trackingActions.viewOTPEmailVerificationPageDashboard());
    } else if(location.pathname.indexOf(routes.DASHBOARD_CREDIT_EVOLUTION_DETAILS_PAGE) > -1){
        yield put(trackingActions.viewCreditEvolutionPage());
    } else if(location.pathname.indexOf(routes.DASHBOARD_PAYMENT_PROTECTION_PLAN_PAGE) > -1){
        yield put(trackingActions.viewPaymentProtectionPlanEnroll());
    } else if(location.pathname.indexOf(routes.DASHBOARD_PAYMENT_PROTECTION_ACKNOWLEDGEMENT_PAGE) > -1){
        yield put(trackingActions.viewInsuranceDisclosures());
    } else if(location.pathname.indexOf(routes.DASHBOARD_PRE_AUTH_DEBIT_SET_UP_PAGE) > -1){
        yield put(trackingActions.viewPADAuthSetUp());
    } else if(location.pathname.indexOf(routes.DASHBOARD_PRE_AUTH_DEBIT_SUMMARY_PAGE) > -1){
        yield put(trackingActions.viewPADAuthSummary());
    } else if(location.pathname.indexOf(routes.DASHBOARD_PRE_AUTH_DEBIT_CONFIRMATION_PAGE) > -1){
        yield put(trackingActions.viewPADAuthConfirmation());
    } else if (location.pathname.indexOf(routes.PROFILE_VIEW_ACH_AUTH_PAGE) > -1) {
        yield put(trackingActions.viewAchAuthPage());
    } else if (location.pathname.indexOf(routes.ACH_PAYMENT_SCHEDULED_PAGE) > -1) {
        yield put(trackingActions.viewAchPaymentScheduledPage())
    } else if (location.pathname.indexOf(routes.ACH_PAYMENT_PAGE) > -1) {
        yield put(trackingActions.viewScheduleAchPaymentPage())
    }
}

// -------------------------- Application --------------------------

export function* miscApplicationActions(action) {
    yield changeTitleForApplication();
    yield trackApplication();
}

function* changeTitleForApplication() {
    let {location, content} = yield select(state => ({
        location: state[field.REDUCER_NODE_ROUTER].location,
        content: state[field.REDUCER_NODE_ORGANIZATION][field.ORGANIZATION.ORG_CONTENT]
    }));
    if (location.pathname.indexOf(routes.APPLICATION_STATE_INFO) > -1) {
        setBrowserTitle(`Apply now | ${content.common.orgNameForBrowserTitle}`);
    } else if (location.pathname.indexOf(routes.APPLICATION_START_APP) > -1) {
        setBrowserTitle(`Get Started | ${content.common.orgNameForBrowserTitle}`);
    } else if (location.pathname.indexOf(routes.APPLICATION_STATE_SELECTION) > -1) {
        setBrowserTitle(`State Selection | ${content.common.orgNameForBrowserTitle}`);
    } else if (location.pathname.indexOf(routes.APPLICATION_REQUIREMENTS) > -1) {
        setBrowserTitle(`Application Requirements | ${content.common.orgNameForBrowserTitle}`);
    } else if (location.pathname.indexOf(routes.APPLICATION_CREATE_PASSWORD) > -1) {
        setBrowserTitle(`Create Password | ${content.common.orgNameForBrowserTitle}`);
    } else if (location.pathname.indexOf(routes.APPLICATION_ACCOUNT_TERMS) > -1) {
        setBrowserTitle(`Consents | ${content.common.orgNameForBrowserTitle}`);
    } else if (location.pathname.indexOf(routes.APPLICATION_PERSONAL_INFO) > -1) {
        setBrowserTitle(`Personal Information | ${content.common.orgNameForBrowserTitle}`);
    } else if (location.pathname.indexOf(routes.APPLICATION_ADDRESS_INFO) > -1) {
        setBrowserTitle(`Address Information | ${content.common.orgNameForBrowserTitle}`);
    } else if (location.pathname.indexOf(routes.APPLICATION_LIVING_SITUATION) > -1) {
        setBrowserTitle(`Address Details | ${content.common.orgNameForBrowserTitle}`);
    } else if (location.pathname.indexOf(routes.APPLICATION_EMPLOYMENT_INFO) > -1) {
        setBrowserTitle(`Employment Information | ${content.common.orgNameForBrowserTitle}`);
    } else if (location.pathname.indexOf(routes.APPLICATION_PAYMENT_SCHEDULE) > -1) {
        setBrowserTitle(`${content.application.paySchedulePage.pageTitle} | ${content.common.orgNameForBrowserTitle}`);
    } else if (location.pathname.indexOf(routes.APPLICATION_BANKING_INFO) > -1) {
        setBrowserTitle(`Banking Information | ${content.common.orgNameForBrowserTitle}`);
    } else if (location.pathname.indexOf(routes.APPLICATION_LOAN_DOCS) > -1) {
        setBrowserTitle(`Sign your agreement | ${content.common.orgNameForBrowserTitle}`);
    } else if (location.pathname.indexOf(routes.APPLICATION_CREDIT_ESTIMATE) > -1) {
        setBrowserTitle(`Estimated Credit Limit | ${content.common.orgNameForBrowserTitle}`);
    } else if (location.pathname.indexOf(routes.APPLICATION_REJECT) > -1) {
        setBrowserTitle(`Ineligible at this time | ${content.common.orgNameForBrowserTitle}`);
    } else if (location.pathname.indexOf(routes.APPLICATION_FINAL_STEPS) > -1) {
        setBrowserTitle(`You're pre-approved! | ${content.common.orgNameForBrowserTitle}`);
    } else if (location.pathname.indexOf(routes.EXTERNAL_APPLICATION_WELCOME) > -1) {
        setBrowserTitle(`Welcome | ${content.common.orgNameForBrowserTitle}`);
    } else if (location.pathname.indexOf(routes.EXTERNAL_APPLICATION_PHONE_VERIFICATION) > -1) {
        setBrowserTitle(`Contact Information | ${content.common.orgNameForBrowserTitle}`);
    } else if (location.pathname.indexOf(routes.APPLICATION_VERIFY_PHONE) > -1) {
        setBrowserTitle(`Verify Phone Number | ${content.common.orgNameForBrowserTitle}`);
    } else if (location.pathname.indexOf(routes.EXTERNAL_APPLICATION_PHONE_VERIFICATION) > -1) {
        setBrowserTitle(`Phone Verification | ${content.common.orgNameForBrowserTitle}`);
    } else if (location.pathname.indexOf(routes.APPLICATION_VERIFYYOURIDENTITY) > -1) {
        setBrowserTitle(`Identity Verification | ${content.common.orgNameForBrowserTitle}`);
    } else if (location.pathname.indexOf(routes.EXTERNAL_APPLICATION_CREATE_ACCOUNT) > -1) {
        setBrowserTitle(`Create Account | ${content.common.orgNameForBrowserTitle}`);
    } else if (location.pathname === routes.APPLICATION_OTP_EMAIL_VERIFICATION) {
        setBrowserTitle(`${content.application.otpEmailVerification.pageTitle} | ${content.common.orgNameForBrowserTitle}`);
    } else if (location.pathname === routes.DASHBOARD_CREDIT_EVOLUTION_DETAILS_PAGE) {
        setBrowserTitle(`Credit Evolution Program | ${content.common.orgNameForBrowserTitle}`);
    } else if (location.pathname === routes.DASHBOARD_PAYMENT_PROTECTION_PLAN_PAGE) {
        setBrowserTitle(`Payment Protection Plan | ${content.common.orgNameForBrowserTitle}`);
    }
}

function* trackApplication() {
    let {location} = history;
    /*
    Note
    Tracking of Viewing loan docs is done through LoanSaga
    Tracking of Viewing welcome page is done in WelcomeForm.jsx
    */
    if (location.pathname.indexOf(routes.APPLICATION_ACCOUNT_TERMS) > -1) {
        let isAuthenticated = yield select(state => state[field.REDUCER_NODE_AUTH][field.AUTH.ISAUTHENTICATED]);
        if (isAuthenticated) {
            yield put(trackingActions.viewAccountTerms());
        }
    } else if (location.pathname.indexOf(routes.APPLICATION_REQUIREMENTS) > -1) {
        let isAuthenticated = yield select(state => state[field.REDUCER_NODE_AUTH][field.AUTH.ISAUTHENTICATED]);
        if (isAuthenticated) {
            yield put(trackingActions.viewApplicationRequirements());
        }
    } else if (location.pathname.indexOf(routes.APPLICATION_PERSONAL_INFO) > -1) {
        // Login is triggered upon submitting create passsword page.
        // Sometimes, personal info is rendered even before login request is successfully completed.
        // So, make a check that user is authenticated before triggering tracking of personal info.
        let isAuthenticated = yield select(state => state[field.REDUCER_NODE_AUTH][field.AUTH.ISAUTHENTICATED]);
        if (isAuthenticated) {
            yield put(trackingActions.viewPersonalInfo());
        }
    } else if (location.pathname.indexOf(routes.APPLICATION_ADDRESS_INFO) > -1) {
        yield put(trackingActions.viewAddressInfo());
    } else if (location.pathname.indexOf(routes.APPLICATION_VERIFY_PHONE) > -1) {
        yield put(trackingActions.viewVerifyPhone());
    } else if (location.pathname.indexOf(routes.APPLICATION_LIVING_SITUATION) > -1) {
        yield put(trackingActions.viewLivingSituation());
    } else if (location.pathname.indexOf(routes.APPLICATION_EMPLOYMENT_INFO) > -1) {
        yield put(trackingActions.viewEmploymentInfo());
    } else if (location.pathname.indexOf(routes.APPLICATION_PAYMENT_SCHEDULE) > -1) {
        yield put(trackingActions.viewPaymentSchedule());
    } else if (location.pathname.indexOf(routes.APPLICATION_BANKING_INFO) > -1) {
        yield put(trackingActions.viewBankingInfo());
    } else if (location.pathname.indexOf(routes.APPLICATION_CREDIT_ESTIMATE) > -1) {
        yield put(trackingActions.viewPreApproved());
    } else if (location.pathname.indexOf(routes.APPLICATION_REJECT) > -1) {
        // There are cases when user is not authenticated, but goes to reject page.
        // For ex On state info page, if they select a not supported state, then user goes to reject page
        // but tracking can not happen, because session is not created.
        let isAuthenticated = yield select(state => state[field.REDUCER_NODE_AUTH][field.AUTH.ISAUTHENTICATED]);
        if (isAuthenticated) {
            yield put(trackingActions.viewRejectPage());
        }
    } else if (location.pathname.indexOf(routes.APPLICATION_FINAL_STEPS) > -1) {
        yield put(trackingActions.viewFinalSteps());
    } else if (location.pathname.indexOf(routes.EXTERNAL_APPLICATION_PHONE_VERIFICATION) > -1) {
        yield put(trackingActions.viewPhoneVerification());
    } else if (location.pathname.indexOf(routes.EXTERNAL_APPLICATION_CREATE_ACCOUNT) > -1) {
        yield put(trackingActions.viewCreateAccount());
    } else if (location.pathname.indexOf(routes.DASHBOARD_VIEW_MYACCOUNT) > -1) {
        yield put(trackingActions.viewAccountPage());
    } else if (location.pathname.indexOf(routes.APPLICATION_OTP_EMAIL_VERIFICATION) > -1) {
        yield put(trackingActions.viewOTPEmailVerificationPageApplication());
    }
}
